<template>
  <div class="card profile_card">
    <div class="card-body">
      <div class="media">
        <img
          v-if="profile?.orang_photo !== null"
          class="mr-3 rounded-circle mr-0 mr-sm-3"
          :src="profile?.orang_photo"
          width="60"
          height="60"
          alt=""
        />
        <img
          v-else
          class="mr-3 rounded-circle mr-0 mr-sm-3"
          src="https://secure.gravatar.com/avatar/"
          width="60"
          height="60"
          alt=""
        />
        <div class="media-body">
          <span>Hello</span>
          <h4 class="mb-2">
            {{ profile?.orang_name || profile?.orang_nickname }}
          </h4>
          <p class="mb-1">
            <span><i class="fa fa-envelope mr-2 text-primary"></i></span>
            {{ profile?.orang_email }}
          </p>
        </div>
      </div>

      <ul class="card-profile__info">
        <li>
          <h5 class="mr-4">Address</h5>
          <span v-if="profile?.orang_address !== null" class="text-muted">{{
            profile?.orang_address
          }}</span>
          <span v-else class="text-muted">__</span>
        </li>
        <li class="mb-1">
          <h5 class="mr-4">Country</h5>
          <span v-if="profile?.orang_country !== null">{{
            profile?.orang_country
          }}</span>
          <span v-else class="text-muted">__</span>
        </li>
        <li class="mb-1">
          <h5 class="mr-4">Birthday</h5>
          <span v-if="profile?.orang_birthday !== null">{{
            profile?.orang_birthday
          }}</span>
          <span v-else class="text-muted">__</span>
        </li>
        <li class="mb-1">
          <h5 class="mr-4">Profession</h5>
          <span v-if="profile?.orang_profession !== null">{{
            profile?.orang_profession
          }}</span>
          <span v-else class="text-muted">__</span>
        </li>
        <!-- TODO: disini nanti jika status active maka green -->
        <li>
          <h5 class="mr-4">KYC Status</h5>
          <span v-if="profile.orang_kyc_approve === 'true'" class="text-success"
            >Active</span
          >
          <span
            v-else-if="profile.orang_kyc_approve === 'In Review'"
            class="text-warning"
            >In Review</span
          >
          <span
            v-else-if="profile.orang_kyc_approve === 'Rejected'"
            class="text-danger"
            >Rejected</span
          >
          <span v-else class="text-danger">Not Active</span>
        </li>
      </ul>
      <!-- <div class="social-icons">
        <a class="facebook text-center" href="javascript:void(0)"
          ><span><i class="fa fa-facebook"></i></span
        ></a>
        <a class="twitter text-center" href="javascript:void(0)"
          ><span><i class="fa fa-twitter"></i></span
        ></a>
        <a class="youtube text-center" href="javascript:void(0)"
          ><span><i class="fa fa-youtube"></i></span
        ></a>
        <a class="googlePlus text-center" href="javascript:void(0)"
          ><span><i class="fa fa-google"></i></span
        ></a>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";

// stores
import { useProfileStore } from "../../stores/profile-store";

const profileStore = useProfileStore();
const profile = computed(() => profileStore.profile);

// const profileImage =
//   profile?.value.orang_photo !== null
//     ? profile?.value.orang_photo
//     : "https://secure.gravatar.com/avatar/";
// console.log("img: ", profileImage);
</script>
