<template>
  <div class="col-xl-12">
    <div class="card">
      <div class="card-body">
        <form
          v-if="isinputActive === true"
          @submit.prevent="submit"
          class="two_factor_Auth_validate"
        >
          <div class="form-row">
            <div class="form-group col">
              <p class="mb-2">Your Wallet Address</p>
              <input
                type="text"
                class="form-control input_a pr-5"
                v-model="address"
              />
              <CopyButton :value="address" :top="42" />
            </div>
            <div class="col-12">
              <button class="btn btn-success waves-effect">
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-else>Update Address</span>
              </button>
            </div>
          </div>
        </form>

        <div v-else>
          <div class="active-subscription-notice">
            <div class="notice-content">
              <i class="las la-search"></i>
              <span
                >No withdraw address saved, please add an withdraw address</span
              >
              <button
                @click="handleAddAddress"
                class="btn btn-success waves-effect mt-4"
              >
                Add Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- use the modal component, pass in the prop -->
    <ModalTwofa :show="showModal" @close="showModal = false" :address="address">
    </ModalTwofa>
  </div>
</template>

<script>
// modules
import Cookies from "js-cookie";
import axios from "axios";

// stores
import { useProfileStore } from "../../stores/profile-store";
import { BASE_API } from "../../applibs/http-common";

// components
import CopyButton from "../global/CopyButton.vue";
import ModalTwofa from "../modals/ModalTwofa.vue";

export default {
  components: {
    CopyButton,
    ModalTwofa,
  },
  data() {
    return {
      profileStore: useProfileStore(),
      showModal: false,
      token: Cookies.get("jwt"),
      isinputActive: true,
      address: null,
      google_secret_or_otp: "",
    };
  },
  methods: {
    submit() {
      if (this.profile.two_fa_is_active) {
        this.showModal = true;
      } else {
        this.profileStore.walletUpdate(this.address, this.google_secret_or_otp);
      }
    },

    async getWalletAddress() {
      try {
        const res = await axios({
          method: "get",
          headers: {
            JWT: this.token,
          },
          url: `${BASE_API}/wallets/my-wallet`,
        });
        this.address = res.data.data.address;
        if (res.data.data.address === null) {
          this.isinputActive = false;
        }
      } catch (err) {
        console.error(err);
      }
    },
    handleAddAddress() {
      this.isinputActive = true;
    },
  },

  computed: {
    loading() {
      return this.profileStore.updateAddressLoading;
    },
    profile() {
      return this.profileStore.profile;
    },
  },

  async mounted() {
    this.getWalletAddress();
  },
};
</script>

<style scoped>
.input_a {
  font-size: 14px;
}

.active-subscription-notice {
  text-align: center;
  padding: 20px;
  border: 2px solid #28a745;
  border-radius: 10px;
  background-color: #d4edda;
  color: #155724;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notice-content i {
  font-size: 2.5rem;
  color: #28a745;
  margin-bottom: 10px;
}

.notice-content h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #28a745;
}

.notice-content p {
  font-size: 1rem;
  margin: 0;
}
</style>
