<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <h2 class="m-auto text-light">2FA Verification</h2>

          <button
            class="btn rounded-circle p-1"
            style="position: absolute; left: 77%"
            @click="show = false"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </button>
        </div>

        <div class="modal-body">
          <form
            @submit.prevent="sendOtp"
            class="d-flex flex-column flex-wrap align-items-center justify-content-center"
          >
            <h5 class="m-0 text-light">Enter 2FA Code</h5>

            <div @paste="getClipboardData" class="otp-inputs my-5">
              <input
                v-model="otp1"
                type="text"
                maxlength="1"
                @input="autoFocus($event, 'input2')"
                @keydown.backspace="autoFocusBack($event, 'input1')"
                @paste.prevent
                ref="input1"
              />
              <input
                v-model="otp2"
                type="text"
                maxlength="1"
                @input="autoFocus($event, 'input3')"
                @keydown.backspace="autoFocusBack($event, 'input1')"
                @paste.prevent
                ref="input2"
              />
              <input
                v-model="otp3"
                type="text"
                maxlength="1"
                @input="autoFocus($event, 'input4')"
                @keydown.backspace="autoFocusBack($event, 'input2')"
                @paste.prevent
                ref="input3"
              />
              <input
                v-model="otp4"
                type="text"
                maxlength="1"
                @input="autoFocus($event, 'input5')"
                @keydown.backspace="autoFocusBack($event, 'input3')"
                @paste.prevent
                ref="input4"
              />
              <input
                v-model="otp5"
                type="text"
                maxlength="1"
                @input="autoFocus($event, 'input6')"
                @keydown.backspace="autoFocusBack($event, 'input4')"
                @paste.prevent
                ref="input5"
              />
              <input
                v-model="otp6"
                type="text"
                maxlength="1"
                @keydown.backspace="autoFocusBack($event, 'input5')"
                @paste.prevent
                ref="input6"
              />
            </div>

            <button type="submit" class="btn btn_sbt mt-1 mb-4 px-5 py-2">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

export default {
  props: {
    show: Boolean,
    address: String,
  },
  data() {
    return {
      profileStore: useProfileStore(),
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    };
  },
  methods: {
    autoFocus(event, nextInput) {
      if (event.target.value.length === 1) {
        this.$refs[nextInput].focus();
      }
    },
    autoFocusBack(event, previousInput) {
      if (event.target.value.length === 0) {
        this.$refs[previousInput].focus();
      }
    },
    joinOtp() {
      return [
        this.otp1,
        this.otp2,
        this.otp3,
        this.otp4,
        this.otp5,
        this.otp6,
      ].join("");
    },
    sendOtp() {
      const google_secret_or_otp = this.joinOtp();
      this.profileStore.walletUpdate(this.address, google_secret_or_otp);
    },
    getClipboardData(e) {
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text");
      this.pasteOtp(pastedData);
    },
    pasteOtp(data) {
      for (let i = 0; i < data.length && i < 6; i++) {
        this[`otp${i + 1}`] = data[i];
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  /* height: 95%; */
  display: flex;
  flex-direction: column;
  margin: auto;
  /* padding: 20px 1.5rem; */
  /* background-color: #fff; */
  background-color: #1e222d;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  /* position: relative; */
  /* margin: 20px 0; */
  /* height: 100%; */
  padding: 3rem 0;
  /* margin: 0 40px; */
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}

.otp-inputs input {
  width: 40px;
  height: 40px;
  border: none;
  border-bottom: 3px solid #708cc7;
  background-color: transparent;
  text-align: center;
  font-size: 2rem;
  color: white;
}

.otp-inputs input:focus {
  outline: none;
  border-color: white;
}

.btn_sbt {
  background-color: #7b6ffb;
}

.btn_sbt:hover {
  background-color: #5f53e8;
  color: #ffff;
}

@media (max-width: 900px) {
  .modal-container {
    width: 55vw;
    height: 45vh;
  }
}

@media (max-width: 500px) {
  .modal-container {
    width: 95vw;
    height: 75%;
  }
}
</style>
