<template>
  <form-page>
    <router-link to="/auth/sign-in" class="page-back text-muted">
      <span><i class="fa fa-angle-left"></i></span> Back
    </router-link>
    <h3 class="text-center">Email OTP</h3>
    <p class="text-center mb-5">Please enter the 5 digit OTP in your email</p>
    <form @submit.prevent="submit" class="two_factor_auth_validate">
      <div class="form-group">
        <validate-field
          title="OTP Code"
          field="otp_code"
          :validations="$v.form.emailOtpCode"
        >
          <div class="input-group mb-3">
            <input
              v-model="form.emailOtpCode"
              @input="handleInputNumberOnly"
              type="text"
              name="otp_code"
              class="form-control text-center font-weight-bold"
              placeholder="1 2 3 4 5"
              maxlength="5"
              autocomplete="off"
            />
          </div>
          <p class="text-center">
            Didn't receive OTP?
            <a @click="requestEmailOtp" class="text-primary c-pointer"
              >Resend OTP</a
            >
          </p>
        </validate-field>
      </div>
      <div class="text-center">
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-success btn-block"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </form>
  </form-page>
</template>

<script>
// stores
import { useAuthStore } from "../../stores/auth-store";

// modules
import { required } from "vuelidate/lib/validators";

// components
import formPage from "@/components/FormPage.vue";
import validateField from "@/components/ValidateField.vue";

export default {
  components: { validateField, formPage },

  data() {
    return {
      form: {
        email: "",
        password: "",
        emailOtpCode: "",
      },
      authStore: useAuthStore(),
    };
  },

  validations: {
    form: {
      emailOtpCode: { required },
    },
  },

  computed: {
    loading() {
      return this.authStore.loading;
    },
    requestEmailOtpLoading() {
      return this.authStore.requestEmailOtpLoading;
    },
  },

  mounted() {
    this.getCredentialTemp();
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.authStore.postEmailTwoFactorAuth(
          this.form.email,
          this.form.password,
          this.form.emailOtpCode
        );
      }
    },

    async requestEmailOtp() {
      if (!this.requestEmailOtpLoading) {
        await this.authStore.postRequestEmailOtp(
          this.form.email,
          this.form.password
        );
      }
    },

    getCredentialTemp() {
      const emailTemp = sessionStorage.getItem("temp_email");
      const passwordTemp = sessionStorage.getItem("temp_password");

      if (emailTemp && passwordTemp) {
        this.form.email = emailTemp;
        this.form.password = passwordTemp;
      }
    },

    handleInputNumberOnly() {
      this.form.emailOtpCode = this.form.emailOtpCode.replace(/[^0-9]/g, "");
    },
  },
};
</script>
