<template>
  <verification-body>
    <form
      @submit.prevent="$router.push('/auth/sign-in')"
      class="identity-upload"
    >
      <div v-if="!form.verificationCode">
        <div class="identity-content">
          <span class="icon icon-error"><i class="fa fa-times"></i></span>
          <h4>Sorry</h4>
          <p>To access this page a verification code is required.</p>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-error pl-5 pr-5">Back</button>
        </div>
      </div>

      <div v-else-if="succesMsg">
        <div class="identity-content">
          <span class="icon"><i class="fa fa-check"></i></span>
          <h4>Success</h4>
          <p>
            {{ succesMsg }}
          </p>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-success pl-5 pr-5">
            Continue
          </button>
        </div>
      </div>

      <div v-else-if="errorMsg">
        <div class="identity-content">
          <span class="icon icon-error"><i class="fa fa-times"></i></span>
          <h4>Sorry</h4>
          <p>{{ errorMsg }}</p>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-error pl-5 pr-5">Back</button>
        </div>
      </div>
    </form>

    <!-- <form
      v-else-if="succesMsg"
      @submit.prevent="$router.push('/auth/sign-in')"
      class="identity-upload"
    >
      <div class="identity-content">
        <span class="icon"><i class="fa fa-check"></i></span>
        <h4>Success</h4>
        <p>
          {{ succesMsg }}
        </p>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-success pl-5 pr-5">
          Continue
        </button>
      </div>
    </form> -->

    <!-- <form
      v-else
      @submit.prevent="$router.push('/auth/sign-in')"
      class="identity-upload"
    >
      <div class="identity-content">
        <span class="icon icon-error"><i class="fa fa-times"></i></span>
        <h4>Sorry</h4>
        <p>{{ errorMsg }}</p>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-error pl-5 pr-5">Back</button>
      </div>
    </form> -->
  </verification-body>
</template>

<script>
// stores
import { useAuthStore } from "../../stores/auth-store";

// components
import verificationBody from "../../components/dashboard/VerificationBody.vue";

export default {
  components: { verificationBody },

  data() {
    return {
      form: {
        verificationCode: "",
      },
      authStore: useAuthStore(),
    };
  },

  computed: {
    loading() {
      return this.authStore.loading;
    },
    succesMsg() {
      return this.authStore.successMsg;
    },
    errorMsg() {
      return this.authStore.errorMsg;
    },
  },

  mounted() {
    this.submit();
  },

  methods: {
    async submit() {
      this.getQueryData();

      if (this.form.verificationCode) {
        await this.authStore.postEmailVerification(this.form.verificationCode);
      }
    },

    getQueryData() {
      this.form.verificationCode = this.$route.query.verification_code;
    },
  },
};
</script>
