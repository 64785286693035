import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_API } from "../applibs/http-common";
import { toast } from "../helpers/toast";

export const useDepositStore = defineStore("deposit", () => {
  // data state
  const depositHistory = ref(null);
  const depositDetail = ref({});

  // loading state
  const createLoading = ref(false);
  const historyLoading = ref(false);
  const detailLoading = ref(false);

  function depositDetailFinder(data, id) {
    const result = data.find((item) => item.id == id);

    if (result) {
      depositDetail.value = {
        coinPaymentDetail: result.coinpayment_res.result,
        network: result.network,
        dateUpdate: result.updated_at,
        paymentStatus: result.paid,
      };

      return true;
    }

    return false;
  }

  async function postDepositCreate(wusd_amount) {
    try {
      createLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/deposit/create`,
        data: {
          wusd_amount,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg);

        // berfungsi untuk refresh data
        getTransactionHistory();
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      createLoading.value = false;
    }
  }

  async function getTransactionHistory(page, rows, order) {
    try {
      historyLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/deposit/history?page=${page}&rows=${rows}&${order}`,
      });

      if (res.data.success && res.data.data.datas.length > 0) {
        depositHistory.value = res.data.data.datas;
      }
    } catch (err) {
      console.error(err);
    } finally {
      historyLoading.value = false;
    }
  }

  async function getDepositDetail(id) {
    try {
      detailLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/deposit/history`,
      });

      if (res.data.success && res.data.data.datas.length > 0) {
        depositDetailFinder(res.data.data.datas, id) ||
          this.$router.push("/dashboard/buy-sell");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        detailLoading.value = false;
      }, 500);
    }
  }

  return {
    depositHistory,
    depositDetail,
    createLoading,
    historyLoading,
    detailLoading,
    postDepositCreate,
    getTransactionHistory,
    getDepositDetail,
  };
});
