<template>
  <form-page>
    <template #header>
      <h4 class="card-title">Sign up your account</h4>
    </template>

    <form @submit.prevent="submit" name="myform" class="signup_validate">
      <validate-field
        title="Email"
        field="email"
        :validations="$v.form.email"
        placeholder="hello@wavedex.io"
      />

      <validate-field
        title="Referral"
        field="referral"
        :validations="$v.form.referral"
        placeholder="Enter referral"
      />

      <validate-field
        title="Password"
        field="password"
        :custom-errors="customErrors.password"
        :validations="$v.form.password"
      >
        <input
          v-model="form.password"
          id="password"
          type="password"
          name="password"
          class="form-control"
          placeholder="Enter new password"
          ref="password"
        />
        <toggle-password @is-password-show="togglePasswordValue" />
      </validate-field>

      <validate-field
        title="Confirm Password"
        field="confirm_password"
        :custom-errors="customErrors.confirmPassword"
        :validations="$v.form.confirmPassword"
      >
        <input
          v-model="form.confirmPassword"
          id="confirm_password"
          type="password"
          name="password"
          class="form-control"
          placeholder="Enter confirm password"
          ref="confirmPassword"
        />
        <toggle-password @is-password-show="toggleConfirmPasswordValue" />
      </validate-field>

      <validate-field
        field="confirm_password"
        :custom-errors="customErrors.termsAndConditions"
        :validations="$v.termsAndConditions"
        class="d-flex"
      >
        <div>
          <label class="m-0">
            <input
              v-model="termsAndConditions"
              @click="tncModalHandler"
              type="checkbox"
              ref="tncCheckbox"
              class="toggle-checkbox"
            />
            <div class="toggle-switch"></div>
            <span class="toggle-label">Accept Terms and Conditions</span>
          </label>
        </div>
      </validate-field>

      <div class="text-center mt-4">
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-success btn-block"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Sign up</span>
        </button>
      </div>
    </form>
    <div class="new-account mt-3">
      <p>
        Already have an account?
        <router-link class="text-primary" to="sign-in">Sign in</router-link>
      </p>
    </div>

    <modal-tnc
      :show="showModalTNC"
      @close="tncHandler(false)"
      @accept="tncHandler(true)"
    />
  </form-page>
</template>

<script>
// stores
import { useAuthStore } from "../../stores/auth-store";

// modules
import { required, email, sameAs } from "vuelidate/lib/validators";

// components
import formPage from "@/components/FormPage.vue";
import validateField from "@/components/ValidateField.vue";
import togglePassword from "../../components/global/TogglePassword.vue";
import modalTnc from "../../components/modals/ModalTnc.vue";

const tncChecker = (value) => value;

export default {
  components: { validateField, formPage, togglePassword, modalTnc },

  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        referral: this.$route.query.ref,
      },
      customErrors: {
        password: { required: "Please provide a password" },
        confirmPassword: {
          required: "Please provide a password",
          sameAsPassword: "Password do not match",
        },
        termsAndConditions: {
          tncChecker: "Please check the Terms and Conditions box",
        },
      },
      authStore: useAuthStore(),
      termsAndConditions: false,
      showModalTNC: false,
    };
  },

  validations: {
    form: {
      email: { required, email },
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      referral: { required },
    },
    termsAndConditions: { tncChecker },
  },

  computed: {
    loading() {
      return this.authStore.loading;
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.authStore.postSignUp(
          this.form.email,
          this.form.password,
          this.form.confirmPassword,
          this.form.referral
        );
      }
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },

    toggleConfirmPasswordValue(val) {
      const password = this.$refs.confirmPassword;
      password.type = val ? "text" : "password";
    },

    tncModalHandler() {
      if (this.$refs.tncCheckbox.checked) {
        this.showModalTNC = true;
      }
    },

    tncHandler(value) {
      this.showModalTNC = false;
      this.$refs.tncCheckbox.checked = value;
      this.termsAndConditions = value;
    },
  },
};
</script>
