<script setup>
defineProps({
  show: Boolean,
  isSuccessUpdate: Boolean,
});
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <h3 >Terms and Conditions</h3>
        </div>

        <div class="modal-body px-5 text-center" ref="tnc" @scroll="scrollToAccept">
          <strong>Wavedex Privacy Policy</strong>
          <ol class="mt-3">
            <li>
              Introduction
              <p>
                Wavedex ("Company", "we", "us", or "our") is committed to
                protecting your privacy. This Privacy Policy explains how we
                collect, use, disclose, and safeguard your information when you
                visit our website
                <a href="https://wavedex.io" target="_blank">
                  https://wavedex.io
                </a>
                , use our services, or participate in our investment program
                (the "Program"). Please read this Privacy Policy carefully. If
                you do not agree with the terms of this Privacy Policy, please
                do not access our website or participate in the Program. By
                accessing or using
              </p>
            </li>
            <li>
              Information We Collect
              <p>
                We may collect personal information that you voluntarily provide
                to us when you register on the website, participate in the
                Program, or contact us for any reason. This information may
                include:
                <ol type="a">
                  <li>
                    <p>
                      Personal Identification Information: Name, email address, phone number, mailing address.
                    </p>
                  </li>
                  <li>
                    <p>
                      Financial Information: Bank account details, investment amounts, transaction history.
                    </p>
                  </li>
                  <li>
                    <p>
                      Usage Data: Information about how you use our website and services, such as IP addresses, browser type, operating system, referring URLs, and access times.
                    </p>
                  </li>
                </ol>
              </p>
            </li>
            <li>
              How We Use Your Information
              <p>
                We use the information we collect for various purposes, including:
                <ol type="a">
                  <li>
                    <p>
                      To operate and maintain our website.
                    </p>
                  </li>
                  <li>
                    <p>
                      To process your investments and manage your participation in the Program.
                    </p>
                  </li>
                  <li>
                    <p>
                      To communicate with you, including sending updates and information about your investment.
                    </p>
                  </li>
                  <li>
                    <p>
                      To improve our website and services.
                    </p>
                  </li>
                  <li>
                    <p>
                      To comply with legal and regulatory requirements.
                    </p>
                  </li>
                </ol>
              </p>
            </li>
            <li>
              Disclosure of Your Information
              <p>
                We may share your information in the following circumstances:
                <ol type="a">
                  <li>
                    <p>
                      With Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.
                    </p>
                  </li>
                  <li>
                    <p>
                      For Legal Compliance: We may disclose your information to comply with applicable laws and regulations, respond to legal process, or protect our rights and property.
                    </p>
                  </li>
                  <li>
                    <p>
                      Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.
                    </p>
                  </li>
                </ol>
              </p>
            </li>
            <li>
              Security of Your Information
              <p>
                We use administrative, technical, and physical security measures to protect your personal information. While we have implemented these measures to secure your information, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the absolute security of your information.
              </p>
            </li>
            <li>
              Your Data Protection Rights
              <p>
                Depending on your location, you may have the following rights regarding your personal information:
                <ol type="a">
                  <li>
                    <p>
                      The right to access – You have the right to request copies of your personal information.
                    </p>
                  </li>
                  <li>
                    <p>
                      The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.
                    </p>
                  </li>
                  <li>
                    <p>
                      The right to erasure – You have the right to request that we erase your personal information, under certain conditions.
                    </p>
                  </li>
                  <li>
                    <p>
                      The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.
                    </p>
                  </li>
                  <li>
                    <p>
                      The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.
                    </p>
                  </li>
                  <li>
                    <p>
                      The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our provided contact information.
                    </p>
                  </li>
                </ol>
              </p>
            </li>
            <li>
              Changes to This Privacy Policy
              <p>
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy.
              </p>
            </li>
          </ol>
        </div>

        <div class="modal-footer">
          <button class="modal-default-button border-danger text-danger" @click="$emit('close')">
            Cancel
          </button>
          <button 
            class="modal-default-button"
            :disabled="!isAccept"
            @click="$emit('accept')"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    show: Boolean
  },

  data() {
    return {
      isAccept: false
    }
  },

  methods: {
    scrollToAccept() {
      const tncElement = this.$refs.tnc

      if (tncElement.offsetHeight + tncElement.scrollTop >= tncElement.scrollHeight - 100) {
        this.isAccept = true
      }
    }
  },

  mounted() {
    // untuk mendeteksi scroll pada elemen window
    if (this.show) {
      window.addEventListener('scroll', this.scrollToAccept)
    }
  },

  beforeUnmount() {
    // saat komponen dihancurkan
    window.removeEventListener('scroll', this.scrollToAccept)
  }
}
</script>

<style scoped>
p,
li {
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
}

h1 {
  font-size: 24px;
  padding-bottom: 2rem;
}
ol > li {
  list-style: decimal ;
  font-weight: 700;
}
ol > li ol[type='a'] li {
  margin-left: 1rem;
  list-style: lower-alpha;
  font-weight: 700;
  font-size: 16px;
  /* width: 110%; */
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  max-width: 500px;
  max-height: 85vh;
  margin: auto;
  /* padding: 20px 30px; */
  background-color: #102886;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  border: none;
  display: flex;
  justify-content: center;
}

.modal-header h3 {
  margin-top: 0;
  color: #bfc2c1;
  text-align: center;
}

.modal-body {
  margin: 0 !important;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

.modal-footer {
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-footer button:first-child {
  background: none;
  border: 1px solid #7b6fff;
  color: #7b6fff;
  border-radius: 5px;
  padding: 4px 10px;
}

.modal-footer button:last-child {
  background: #7b6fff;
  border: 1px solid #7b6fff;
  color: #bfc2c1;
  border-radius: 5px;
  padding: 4px 10px;
}

button:disabled {
  filter: brightness(75%);
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.animation_s {
  display: flex;
  justify-content: center;
}
</style>
