<template>
  <button
    @click="togglePassword"
    type="button"
    ref="toggleButton"
    class="d-flex align-items-center p-0 px-2 pr-3 m-0 bg-transparent text-muted"
  >
    <i v-if="isShow" class="las la-eye" style="font-size: 1.5rem"></i>
    <i v-else class="las la-eye-slash" style="font-size: 1.5rem"></i>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      const button = this.$refs.toggleButton;
      if (button.classList.contains("waves-effect")) {
        button.classList.remove("waves-effect");
      }
    });
  },

  methods: {
    togglePassword() {
      this.isShow = !this.isShow;
      this.$emit("is-password-show", this.isShow);
    },
  },
};
</script>

<style scoped>
button {
  width: max-content;
  height: 56.5%;
  border: none;
  position: absolute;
  top: 43.5%;
  right: 1%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>
