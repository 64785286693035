<template>
  <div class="container mt-5">
    <div class="row mb-4">
      <!-- Dropdown to allow the user to change the color of the chart -->
      <div class="col-md-6 mb-3">
        <label for="colorPicker" class="form-label">Choose color:</label>
        <div class="input-group mb-4">
          <select
            id="colorPicker"
            v-model="chartColor"
            @change="updateChartColor"
            class="form-control"
          >
            <option value="#ff6384">Red</option>
            <option value="#4bc0c0">Green</option>
            <option value="#36a2eb">Blue</option>
            <option value="#ff9f40">Orange</option>
          </select>
          <span
            class="input-group-text"
            :style="{ backgroundColor: chartColor }"
          ></span>
        </div>

        <label for="timePeriod" class="form-label">Choose time period:</label>
        <div class="input-group">
          <select
            id="timePeriod"
            v-model="days"
            @change="fetchData"
            class="form-control"
          >
            <option value="7">7 Days</option>
            <option value="30">30 Days</option>
            <option value="60">60 Days</option>
          </select>
        </div>
      </div>

      <!-- Radio buttons to allow the user to select which cryptocurrency to display -->
      <div class="col-md-6 mb-3">
        <label class="form-label">Choose cryptocurrency:</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="currency"
            value="bitcoin"
            v-model="currency"
            @change="fetchData"
          />
          <label class="form-check-label">
            Bitcoin
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 24 24"
            >
              <path
                fill="#f7931a"
                d="M18.012 10.432c.248-1.684-1.027-2.582-2.783-3.192l.568-2.27l-1.386-.345l-.554 2.214l-1.107-.265l.561-2.23L11.924 4l-.57 2.278l-.881-.209v-.008L8.556 5.58l-.37 1.483s1.027.242 1.011.25c.562.144.658.513.642.801l-.642 2.599l.145.048l-.153-.032l-.906 3.633c-.064.169-.241.425-.633.32c.016.024-1.003-.24-1.003-.24l-.69 1.58l1.805.449l.986.257l-.577 2.302l1.387.344l.562-2.277l1.114.288l-.569 2.27l1.387.345l.57-2.302c2.366.449 4.146.273 4.893-1.869c.6-1.724-.033-2.71-1.276-3.368c.906-.2 1.588-.802 1.764-2.03zm-3.169 4.443c-.425 1.725-3.328.786-4.266.561l.762-3.055c.938.24 3.954.698 3.504 2.486zm.426-4.467c-.386 1.564-2.808.77-3.585.578l.689-2.768c.786.193 3.304.562 2.896 2.19"
              />
            </svg>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="currency"
            value="dogecoin"
            v-model="currency"
            @change="fetchData"
          />
          <label class="form-check-label">
            Dogecoin
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.15em"
              height="1em"
              viewBox="0 0 16 14"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M7.72 1.5H4V6H2l-.04 2H4v4.5h3.91c1.68 0 2.08-.2 2.89-.59c.06-.03.13-.06.2-.1c.95-.46 1.69-1.1 2.21-1.92c.53-.83.79-1.79.79-2.89s-.26-2.06-.79-2.89S11.95 2.64 11 2.18S8.96 1.5 7.72 1.5M6.75 8v2.41h.83c1.14 0 2.05-.31 2.73-.92q1.02-.915 1.02-2.49c0-1.575-.34-1.88-1.02-2.49s-1.59-.92-2.73-.92h-.83V6h2.58v2z"
              />
            </svg>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="currency"
            value="ethereum"
            v-model="currency"
            @change="fetchData"
          />
          <label class="form-check-label">
            Ethereum
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="0.62em"
              height="1em"
              viewBox="0 0 256 417"
            >
              <path
                fill="#343434"
                d="m127.961 0l-2.795 9.5v275.668l2.795 2.79l127.962-75.638z"
              />
              <path
                fill="#8c8c8c"
                d="M127.962 0L0 212.32l127.962 75.639V154.158z"
              />
              <path
                fill="#3c3c3b"
                d="m127.961 312.187l-1.575 1.92v98.199l1.575 4.601l128.038-180.32z"
              />
              <path fill="#8c8c8c" d="M127.962 416.905v-104.72L0 236.585z" />
              <path
                fill="#141414"
                d="m127.961 287.958l127.96-75.637l-127.96-58.162z"
              />
              <path fill="#393939" d="m.001 212.321l127.96 75.637V154.159z" />
            </svg>
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="currency"
            value="tether"
            v-model="currency"
            @change="fetchData"
          />
          <label class="form-check-label"
            >Tether

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18.754 10.518c0 .625-2.238 1.148-5.238 1.28l.002.001a23 23 0 0 1-1.5.033c-.779 0-1.33-.023-1.524-.032c-3.006-.133-5.25-.656-5.25-1.282s2.244-1.15 5.25-1.284v2.044c.196.015.759.048 1.537.048c.933 0 1.4-.04 1.485-.047V9.236c3 .133 5.238.657 5.238 1.282m5.19.546L12.124 22.39a.18.18 0 0 1-.249 0L.056 11.064a.18.18 0 0 1-.038-.208l4.376-9.192a.18.18 0 0 1 .163-.103h14.888a.18.18 0 0 1 .16.103l4.377 9.192a.18.18 0 0 1-.038.208m-4.478-.404c0-.806-2.552-1.48-5.947-1.637V7.195h4.186v-2.79H6.308v2.79h4.185v1.829c-3.402.156-5.96.83-5.96 1.637c0 .808 2.558 1.48 5.96 1.638v5.862h3.025v-5.864c3.394-.157 5.948-.83 5.948-1.637"
              />
            </svg>
          </label>
        </div>
      </div>
    </div>

    <!-- <div class="row mb-4">
      <div class="col-md-6 mb-3">
        <label for="timePeriod" class="form-label">Choose time period:</label>
        <select
          id="timePeriod"
          v-model="days"
          @change="fetchData"
          class="form-select"
        >
          <option value="7">7 Days</option>
          <option value="30">30 Days</option>
          <option value="60">60 Days</option>
        </select>
      </div>
    </div> -->

    <div class="row">
      <div class="col">
        <div class="crypto-chart-div">
          <canvas
            id="crypto-chart"
            width="700"
            height="350"
            ref="chart"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Chart from 'chart.js/auto';
import "chartjs-adapter-moment";

export default {
  name: "CryptoApp",
  data() {
    return {
      chart: null,
      prices: [],
      labels: [],
      days: "7",
      chartColor: "#36a2eb",
      currency: "bitcoin",
      showAlert: false,
      errorMessage: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const url = `https://api.coingecko.com/api/v3/coins/${this.currency}/market_chart?vs_currency=usd&days=${this.days}`;
      axios
        .get(url, { timeout: 5000 })
        .then((response) => {
          this.prices = response.data.prices;
          this.labels = response.data.prices.map((price) =>
            new Date(price[0]).toLocaleDateString()
          );
          this.renderChart();
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.showAlert = true;
            this.errorMessage = "The API call timed out. Please try again";
            console.log(error);
          } else {
            this.showAlert = true;
            this.errorMessage = "Oops. An error occurred: " + error;
            console.error(error);
          }
        });
    },
    renderChart() {
      const ctx = this.$refs.chart.getContext("2d");

      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              label:
                this.currency.charAt(0).toUpperCase() + this.currency.slice(1),
              backgroundColor: this.chartColor,
              data: this.prices,
              fill: true,
              pointBackgroundColor: "grey",
              pointRadius: 1.5,
            },
          ],
        },
        options: {
          scales: {
            y: {
              ticks: {
                callback: (value) => {
                  if (value >= 1000) {
                    return (
                      "$" +
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    );
                  }
                  return "$" + value.toFixed(2);
                },
              },
            },
          },
        },
      });
    },
    updateChartColor() {
      this.renderChart();
    },
  },
};
</script>

<style scoped>
.crypto-chart-div {
  margin-bottom: 30px;
}
.form-control {
  width: 150px !important;
}
</style>
