<template>
  <div class="card balance-widget">
    <div class="card-header border-0 py-0">
      <h4 class="card-title">Markets</h4>
    </div>
    <div class="card-body">
      <div class="balance-widget">
        <!-- <div class="total-balance">
          <h3>{{ totalBalance }}</h3>
          <h6>Total Balance</h6>
        </div> -->
        <ul class="list-unstyled">
          <li v-for="(coin, index) in portfolio" :key="index" class="media">
            <i :class="coin.icon + ' mr-3'"></i>
            <div class="media-body">
              <h5 class="m-0">{{ coin.name }}</h5>
            </div>
            <div class="text-right">
              <h5>{{ coin.amount }}</h5>
              <span>{{ coin.value }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      totalBalance: "$0.00",
      portfolio: [],
    };
  },
  methods: {
    async fetchPortfolioData() {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/coins/markets",
          {
            params: {
              vs_currency: "usd",
              ids: "bitcoin,litecoin,ripple,dash",
            },
          }
        );
        const coins = response.data;
        this.portfolio = coins.map((coin) => ({
          icon: `cc ${coin.symbol.toUpperCase()}`,
          name: coin.name,
          amount: `${(Math.random() * 0.001).toFixed(
            6
          )} ${coin.symbol.toUpperCase()}`,
          value: `$${coin.current_price.toFixed(2)} USD`,
        }));
        this.totalBalance = `$${this.portfolio
          .reduce(
            (acc, coin) => acc + parseFloat(coin.value.replace("$", "")),
            0
          )
          .toFixed(2)} USD`;
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      }
    },
  },
  mounted() {
    this.fetchPortfolioData();
  },
};
</script>
