<template>
  <form @submit.prevent="submit" name="transferForm" class="transfer_validate">
    <div class="form-group">
      <label class="mr-sm-2">Balance</label>
      <div class="input-group mb-3">
        <input
          v-model="networkBalance.balance"
          type="text"
          name="balance"
          class="form-control"
          placeholder="1000"
          disabled
        />
      </div>
    </div>

    <validate-field
      title="Transfer To"
      field="to_address"
      :validations="$v.form.toAddress"
    >
      <div class="input-group mb-3">
        <input
          v-model="form.toAddress"
          type="text"
          name="to_address"
          class="form-control"
          placeholder="Address"
        />
      </div>
    </validate-field>

    <validate-field title="Amount" field="amount" :validations="$v.form.amount">
      <div class="input-group mb-3">
        <input
          v-model="form.amount"
          @input="handleInputNumberOnly"
          type="text"
          name="amount"
          class="form-control"
          placeholder="1000"
          autocomplete="off"
        />
      </div>
    </validate-field>


    <button
      type="submit"
      :disabled="loading"
      name="submit"
      class="btn btn-success btn-block mt-3"
    >
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span v-else>Submit</span>
    </button>
  </form>
</template>

<script>
// stores
import { useTransferStore } from "../../stores/transfer-store";
import { useProfileStore } from "../../stores/profile-store";

import validateField from "@/components/ValidateField.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: { validateField },

  data() {
    return {
      form: {
        wallet: "",
        toAddress: null,
        amount: null,
      },
      balance: "",
      transferStore: useTransferStore(),
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      toAddress: { required },
      amount: { required },
    },
  },

  computed: {
    loading() {
      return this.transferStore.createLoading;
    },
    balances() {
      return this.profileStore.balances;
    },
    networkBalance() {
      return this.profileStore.networkBalance;
    }
  },

  watch: {
    "form.wallet"(newVal) {
      this.updateBalance(newVal);
    },
  },

  mounted() {
    // ambil balance A, B, C
    this.profileStore.getBalance();

    // ambil network balance 
    this.profileStore.getNetworkBalance();
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.transferStore.postTransferCreate(
          this.form.toAddress,
          this.form.amount
        );
      }
    },

    updateBalance(walletLabel) {
      const selectedBalance = this.balances.find(
        (balance) => balance.label === walletLabel
      );
      this.balance = selectedBalance ? selectedBalance.value : "";
    },

    handleInputNumberOnly() {
      this.form.amount = this.form.amount.replace(/[^0-9]/g, "");
    },
  },
};
</script>
