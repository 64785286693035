<template>
  <main-wrapper :is-loading="isLoading">
    <dashboard-header />
    <sidebar />
    <router-view name="dashboardPageTitle" />
    <router-view />
    <dashboard-footer />
  </main-wrapper>
</template>

<script setup>
import mainWrapper from "@/components/MainWrapper.vue";
import dashboardHeader from "@/components/dashboard/Header.vue";
import sidebar from "@/components/dashboard/Sidebar.vue";
import pageTitle from "@/components/dashboard/PageTitle.vue";
import dashboardFooter from "@/components/dashboard/Footer.vue";
import app from "@/App.vue";
import { useProfileStore } from "../stores/profile-store";
import { onMounted, ref } from "vue";

const profileStore = useProfileStore();
const isLoading = ref(false);

onMounted(() => {
  profileStore.getProfile();
});
</script>
