@ -0,0 +1,133 @@
<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">KYC Update</h4>
      <strong v-if="profile.orang_kyc_approve === 'true'" class="text-success"
        >Active</strong
      >
      <strong
        v-else-if="profile.orang_kyc_approve === 'In Review'"
        class="text-warning"
        >In Review</strong
      >
      <strong
        v-else-if="profile.orang_kyc_approve === 'Rejected'"
        class="text-danger"
        >Rejected</strong
      >
    </div>
    <div class="card-body">
      <form @submit.prevent="submit" name="kyc_update" class="kyc_update">
        <div class="form-row">
          <validate-field
            title="Document Type"
            field="national_type_id"
            :validations="$v.form.nationalTypeId"
            class="col-xl-6"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <label class="input-group-text">
                  <i class="las la-folder la-lg"></i>
                </label>
              </div>
              <select
                v-model="form.nationalTypeId"
                id="national_type_id"
                class="form-control"
                name="national_type_id"
                :disabled="
                  profile.orang_kyc_approve === 'true' ||
                  profile.orang_kyc_approve === 'In Review'
                "
              >
                <option value="">Select</option>
                <option value="ID card">ID Card</option>
                <option value="Passport">Passport</option>
              </select>
            </div>
          </validate-field>

          <!-- breaker -->
          <div class="col-12"></div>

          <validate-field
            title="Document/ Passport Number"
            field="national_number_id"
            :validations="$v.form.nationalNumberId"
            class="col-xl-6"
          >
            <input
              v-model="form.nationalNumberId"
              type="text"
              name="national_number_id"
              id="national_number_id"
              placeholder="123456789"
              :disabled="
                profile.orang_kyc_approve === 'true' ||
                profile.orang_kyc_approve === 'In Review'
              "
              class="form-control"
            />
          </validate-field>

          <!-- breaker -->
          <div class="col-12"></div>

          <validate-field
            :custom-errors="customErrors.image"
            :validations="$v.form.idCardImage"
            class="col-xl-6"
          >
            <div class="d-flex justify-content-center mb-3">
              <div class="chart-stat image_container">
                <img
                  :src="
                    (imageDetails.idCardImagePreview !==
                      'National card image not found' &&
                      imageDetails.idCardImagePreview) ||
                    '/images/id-card.webp'
                  "
                  height="150"
                  :alt="
                    imageDetails.idCardImagePreview
                      ? 'ID Card Preview'
                      : 'www.vecteezy.com'
                  "
                />
              </div>
            </div>
            <label class="mr-sm-2">ID Card</label>
            <div
              class="file-upload-wrapper"
              :data-text="imageDetails.idCardImageName"
            >
              <input
                @change="imageUploadHandler('idCardImage', $event)"
                name="id_card_file_upload"
                type="file"
                :disabled="
                  profile.orang_kyc_approve === 'true' ||
                  profile.orang_kyc_approve === 'In Review'
                "
                class="id_card_file_upload"
              />
            </div>
          </validate-field>

          <validate-field
            :custom-errors="customErrors.image"
            :validations="$v.form.selfiePhoto"
            class="col-xl-6"
          >
            <div class="d-flex justify-content-center mb-3">
              <div class="chart-stat image_container">
                <img
                  :src="
                    (imageDetails.selfiePhotoPreview !==
                      'Selfi image not found' &&
                      imageDetails.selfiePhotoPreview) ||
                    '/images/selfie-photo.png'
                  "
                  height="150"
                  :alt="
                    imageDetails.selfiePhotoPreview
                      ? 'Selfie Photo Preview'
                      : 'www.freepik.com'
                  "
                />
              </div>
            </div>
            <label class="mr-sm-2">Selfie Photo</label>
            <div
              class="file-upload-wrapper"
              :data-text="imageDetails.selfiePhotoName"
            >
              <input
                @change="imageUploadHandler('selfiePhoto', $event)"
                name="selfie_photo_file_upload"
                type="file"
                :disabled="
                  profile.orang_kyc_approve === 'true' ||
                  profile.orang_kyc_approve === 'In Review'
                "
                class="selfie_photo_file_upload"
              />
            </div>
          </validate-field>

          <button
            type="submit"
            :disabled="
              loading ||
              profile.orang_kyc_approve === 'true' ||
              profile.orang_kyc_approve === 'In Review'
            "
            class="btn btn-success mt-3"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

import validateField from "@/components/ValidateField.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: { validateField },

  data() {
    return {
      form: {
        nationalTypeId: "",
        nationalNumberId: null,
        idCardImage: null,
        selfiePhoto: null,
      },
      imageDetails: {
        idCardImagePreview: "",
        idCardImageName: "ID-Card.jpg",
        selfiePhotoPreview: "",
        selfiePhotoName: "Selfie-Photo.jpg",
      },
      customErrors: {
        image: {
          required: "You must upload an image",
        },
      },
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      nationalTypeId: { required },
      nationalNumberId: { required },
      idCardImage: { required },
      selfiePhoto: { required },
    },
  },

  computed: {
    loading() {
      return this.profileStore.kycUpdateLoading;
    },
    profile() {
      return this.profileStore.profile;
    },
    kyc() {
      return this.profileStore.kyc;
    },
  },

  async mounted() {
    await this.profileStore.getProfile();
    await this.profileStore.getKyc();
    this.updateDataForm();
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        (this.profile.orang_kyc_approve !== "true" ||
          this.profile.orang_kyc_approve !== "In Review")
      ) {
        await this.profileStore.postKycUpdate(
          this.form.nationalTypeId,
          this.form.nationalNumberId,
          this.form.idCardImage,
          this.form.selfiePhoto
        );
      }
    },

    updateDataForm() {
      this.form.nationalTypeId = this.kyc?.type || this.form.nationalTypeId;
      this.form.nationalNumberId = this.kyc?.number_id;
      this.imageDetails.idCardImagePreview = this.kyc?.national_image_url;
      this.imageDetails.selfiePhotoPreview = this.kyc?.selfi_image_url;
    },

    imageUploadHandler(field, event) {
      const file = event.target.files[0];
      const validTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5 MB

      if (file) {
        if (!validTypes.includes(file.type)) {
          this.$toast.warning(
            "Invalid file format. Only JPEG and PNG are allowed."
          );
          return;
        }

        if (file.size > maxSize) {
          this.$toast.warning(
            "File size exceeds 5MB. Please upload a smaller file."
          );
          return;
        }

        this.form[field] = file;
        this.imageDetails[`${field}Name`] = file.name;

        this.previewImageHandler(field, file);
      }
    },

    previewImageHandler(field, file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (field === "idCardImage") {
          this.imageDetails.idCardImagePreview = e.target.result;
        } else if (field === "selfiePhoto") {
          this.imageDetails.selfiePhotoPreview = e.target.result;
        }
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
.image_container {
  min-height: 180px;
  min-width: 180px;
}
</style>
