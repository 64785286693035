import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_API } from "../applibs/http-common";
import { toast } from "../helpers/toast";

function setTokenCookies(token, usid) {
  Cookies.set("jwt", token, { expires: 1 });
  Cookies.set("usid", usid, { expires: 1 });
}

function removeTokenCookies() {
  Cookies.remove("jwt");
  Cookies.remove("usid");
}

function setTempSession(email, password) {
  sessionStorage.setItem("temp_email", email);
  sessionStorage.setItem("temp_password", password);
}

function removeTempSession() {
  sessionStorage.removeItem("temp_email");
  sessionStorage.removeItem("temp_password");
}

/**
 * @param {object} self self harus diinisialisasi terlebih dahulu pada function yang akan digunakan. Contoh: "let self = this"
 */
function timedRouter(self, path, time = 0) {
  setTimeout(() => {
    self.$router.push(path);
  }, time);
}

export const useAuthStore = defineStore("auth", () => {
  const successMsg = ref("");
  const errorMsg = ref("");

  // loading state
  const loading = ref(false);
  const requestEmailOtpLoading = ref(false);

  async function postSignIn(email, password) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/signin_post`,
        data: {
          email,
          password,
        },
      });

      if (res.data.success && res.data.action === "login_complete") {
        setTokenCookies(res.data.token, res.data.usid);
        // this.$router.push("/dashboard");
        window.location.href = "/dashboard";
      } else if (res.data.success && res.data.action === "2fa") {
        setTempSession(email, password);
        this.$router.push("/auth/two-factor-auth");
      } else if (res.data.success && res.data.action === "2fa_email") {
        setTempSession(email, password);
        this.$router.push("/auth/email-two-factor-auth");
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postTwoFactorAuth(email, password, google_secret) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/two-fa-login`,
        data: {
          email,
          password,
          google_secret,
        },
      });

      if (res.data.success && res.data.action === "login_complete") {
        setTokenCookies(res.data.token, res.data.usid);
        removeTempSession();
        // this.$router.push("/dashboard");
        window.location.href = "/dashboard";
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postEmailTwoFactorAuth(email, password, email_otp) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/email-two-fa-login`,
        data: {
          email,
          password,
          email_otp,
        },
      });

      if (res.data.success && res.data.action === "login_complete") {
        setTokenCookies(res.data.token, res.data.usid);
        removeTempSession();
        // this.$router.push("/dashboard");
        window.location.href = "/dashboard";
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postSignOut() {
    try {
      loading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/auth/signout`,
      });

      if (res.data.success) {
        // removeTokenCookies();
        // this.$router.push("/");
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      removeTokenCookies();
      this.$router.push("/");
      loading.value = false;
    }
  }

  async function postSignUp(email, password, password_confirm, upline_shadow) {
    let self = this;

    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/signup_post`,
        data: {
          email,
          password,
          password_confirm,
          upline_shadow,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg, 3000);
        timedRouter(self, "sign-in", 3000);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postRequestEmailVerification(email, recaptchaToken) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/email-verification-token-request`,
        data: {
          email,
          recaptchaToken,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postRequestEmailOtp(email, password) {
    try {
      requestEmailOtpLoading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/email-2fa-login-request-new-otp`,
        data: {
          email,
          password,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      requestEmailOtpLoading.value = false;
    }
  }

  async function postRequestResetPassword(email) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/passchange-request`,
        data: {
          email,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postPasswordChange(email, token, password, password_confirm) {
    let self = this;

    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/passchange-form-post`,
        data: {
          email,
          token,
          password,
          password_confirm,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg, 3000);
        timedRouter(self, "sign-in", 3000);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      loading.value = false;
    }
  }

  async function postEmailVerification(verification_code) {
    try {
      loading.value = true;

      const res = await axios({
        method: "post",
        url: `${BASE_API}/auth/email-verification`,
        data: {
          verification_code,
        },
      });

      if (res.data.success) {
        successMsg.value = res.data.msg;
      } else {
        errorMsg.value = res.data.msg;
      }
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }

  return {
    successMsg,
    errorMsg,
    loading,
    requestEmailOtpLoading,
    postSignIn,
    postTwoFactorAuth,
    postEmailTwoFactorAuth,
    postSignOut,
    postSignUp,
    postRequestEmailVerification,
    postRequestEmailOtp,
    postRequestResetPassword,
    postPasswordChange,
    postEmailVerification,
  };
});
