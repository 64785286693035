<template>
  <div class="package-container">
    <div @click="handleShowModal('3 months')" class="subs_item">
      <img
        src="../../../public/images/subscriptions/subs_months.png"
        alt="3 month"
        width="400"
      />
      <button>Subscribe</button>
    </div>
    <!-- <div @click="handleShowModal('6 months')" class="subs_item">
      <img
        src="../../../public/images/subscriptions/6month.png"
        alt="6 month"
        width="200"
      />
    </div>
    <div @click="handleShowModal('12 months')" class="subs_item">
      <img
        src="../../../public/images/subscriptions/12month.png"
        alt="12 month"
        width="200"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    handleShowModal: Function,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 25px;
  letter-spacing: 5px;
}
h2 {
  font-size: 20px;
  text-align: left;
}
h3 {
  font-size: 15px;
  font-weight: 400;
}
sup {
  font-size: 20px;
  vertical-align: super;
}
li {
  padding: 1rem 0;
  border-bottom: solid rgb(223, 223, 223) 0.1px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  gap: 1rem;
}
li h3 {
  color: black;
}
span {
  line-height: 0;
  font-size: 35px;
}
.package-container {
  /* max-width: 18rem; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  gap: 30px;
}
.package-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  gap: 0.7rem;
  color: white;
}
.package-price {
  padding: 1rem 2rem;
}
.package-price h1 {
  font-size: 60px;
}
.package-time {
  color: white;
  letter-spacing: 3px;
  padding: 0.3rem 0;
}
.package-list {
  margin: 2rem 0;
}
.package-footer {
  margin-bottom: 2rem;
}
.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
}
.button:hover {
  filter: brightness(97%);
}
.button:active {
  filter: brightness(93%);
}
.subs_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  cursor: pointer;
  transition: 0.5s;
}
.subs_item button {
  background-color: #7b6fff;
  padding: 3px 10px;
  border-radius: 5px;
  border: none;
  color: aliceblue;
}
.subs_item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px 0 rgba(94, 90, 90, 0.2);
}

@media only screen and (max-width: 750px) {
  .package-container {
    flex-direction: column;
  }
  .subs_item img {
    width: 50%;
    height: auto;
  }
}
</style>
