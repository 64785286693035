<template>
  <div class="row">
    <div class="col-xl-6">
      <AvatarChange :profile="profile" />
    </div>
    <div class="col-xl-6">
      <changePassword />
    </div>
    <div class="col-xl-12">
      <personal-information :profile="profile" />
    </div>
  </div>
</template>

<script>
import { useProfileStore } from "../../../stores/profile-store";
import app from "@/App.vue";
import personalInformation from "../../../components/profile/PersonalInformation.vue";
import changePassword from "../../../components/profile/ChangePassword.vue";
import AvatarChange from "../../../components/profile/AvatarChange.vue";
import validateField from "@/components/ValidateField.vue";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  extends: app,
  components: {
    validateField,
    personalInformation,
    changePassword,
    AvatarChange,
  },

  data() {
    return {
      fullName: "Maria Pascle",
      email: "hello@wavedex.io",
      dob: "",
      presentAddress: "56, Old Street, Brooklyn",
      permanentAddress: "123, Central Square, Brooklyn",
      city: "New York",
      postal: "25481",
      country: "Jordan",
      fileName: "Change Photo",
      profileStore: useProfileStore(),
    };
  },

  validations: {
    fullName: { required, minLength: minLength(4) },
    dob: { required },
    email: { required, email },
    presentAddress: { required },
    permanentAddress: { required },
    city: { required },
    postal: { required },
    country: { required },
  },

  computed: {
    profile() {
      return this.profileStore.profile;
    },
  },

  mounted() {
    this.profileStore.getProfile();
  },

  methods: {
    updateFileName($e) {
      this.fileName = $e.target.value.replace(/.*(\/|\\)/, "");
    },

    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$router.go(0);
      }
    },
  },
};
</script>
