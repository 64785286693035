<template>
  <content-body>
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="buy-sell-widget">
              <b-tabs v-model="activeTab">
                <b-tab title="Transfer"><transfer-form /></b-tab>
                <!-- <b-tab title="Deposit"><deposit-form /></b-tab> -->
              </b-tabs>
            </div>
          </div>
        </div>
        <!-- <p class="p-4">
          Note: Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
          cupiditate suscipit explicabo voluptas eos in tenetur error temporibus
          dolorum. Nulla!
        </p> -->
      </div>
      <div class="col-xl-7 col-lg-7 col-md-12">
        <img src="/images/transfer.png" alt="transfer" />
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-xl-6 col-xxl-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">FAQ</h4>
                    </div>
                    <div class="card-body">
                        <div id="accordion-faq" class="accordion">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                    <h5 class="mb-0 c-pointer" v-b-toggle.accordion-1>
                                        What Shipping Methods are Available?
                                    </h5>
                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high
                                        life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                        cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                        eiusmod.
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                    <h5 class="mb-0 c-pointer" v-b-toggle.accordion-2>
                                        How Long Will it Take To Get My Package?
                                    </h5>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high
                                        life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                        cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                        eiusmod.
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                    <h5 class="mb-0 c-pointer" v-b-toggle.accordion-3>
                                        How Do I Track My Order?
                                    </h5>
                                </b-card-header>
                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high
                                        life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                        cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                        eiusmod.
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                    <h5 class="mb-0 c-pointer" v-b-toggle.accordion-4>
                                        Do I Need A Account To Place Order?
                                    </h5>
                                </b-card-header>
                                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high
                                        life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                        cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                        eiusmod.
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                    <h5 class="mb-0 c-pointer" v-b-toggle.accordion-5>
                                        How do I Place an Order?
                                    </h5>
                                </b-card-header>
                                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high
                                        life accusamus terry richardson ad squid. 3 wolf moon officia aute, non
                                        cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                                        eiusmod.
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="intro-video-play">
                    <div class="play-btn">
                        <a class="popup-youtube" href="https://www.youtube.com/watch?v=IjzUwnqWc5Q">
                            <span><i class="fa fa-play"></i></span></a>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- Transaction History -->
    <div>
      <TransferTransactionHistory />
      <!-- <DepositTransactionHistory v-else /> -->
    </div>
  </content-body>
</template>

<script>
import contentBody from "@/components/dashboard/ContentBody.vue";
import TransferForm from "@/components/transfer/TransferForm.vue";
import DepositForm from "@/components/deposit/DepositForm.vue";
import TransferTransactionHistory from "@/components/transfer/TransactionHistory.vue";
import DepositTransactionHistory from "@/components/deposit/TransactionHistory.vue";
import app from "@/App.vue";

export default {
  extends: app,
  components: {
    contentBody,
    TransferForm,
    DepositForm,
    TransferTransactionHistory,
    DepositTransactionHistory,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 469px) {
  .col-xl-7 img {
    width: 300px;
  }
}
</style>
