<template>
  <div>
    <div v-if="isTwofactorAuthActivate" class="row">
      <div class="col-xl-6">
        <form @submit.prevent="submit" class="two_factor_Auth_validate">
          <div class="form-row">
            <div class="form-group col-xl-12">
              <validate-field
                title="Disable 2FA"
                field="code"
                :custom-errors="customErrors.googleSecretCode"
                :validations="$v.form.googleSecretCode"
                placeholder="Code"
              />
              <p class="mb-0">Enter the 6-digit code in authenticator</p>
            </div>
            <div class="col-12">
              <button
                type="submit"
                :disabled="twoFactorAuthLoading"
                class="btn btn-success waves-effect"
              >
                <span
                  v-if="twoFactorAuthLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-else>Confirm</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-xl-6">
        <div class="buy-sell-widget">
          <b-tabs>
            <b-tab title="QR Code">
              <div class="chart-stat qr_container mx-auto">
                <span
                  v-if="qrCodeloading"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                ></span>
                <img v-else :src="qrCode.qrUrl" alt="" class="img-fluid" />
              </div>

              <p class="text-center">
                Open the Google Authenticator app and scan this QR Code
              </p>
            </b-tab>

            <b-tab title="Setup Key">
              <div class="form-row">
                <div class="form-group col-xl-12">
                  <label class="mr-sm-2">Account</label>
                  <input
                    v-model="qrCode.accountName"
                    type="text"
                    class="form-control"
                    placeholder="Account"
                    disabled
                  />
                  <CopyButton :value="qrCode.accountName" :top="42" />
                </div>
                <div class="form-group col-xl-12">
                  <label class="mr-sm-2">Key</label>
                  <input
                    v-model="qrCode.key"
                    type="text"
                    class="form-control input_l pr-5"
                    placeholder="Key"
                    disabled
                  />
                  <CopyButton :value="qrCode.key" :top="42" />
                </div>
                <p>
                  Open the Google Authenticator app and enter this Setup Key
                </p>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit" class="two_factor_Auth_validate">
              <div class="form-row">
                <div class="form-group col-xl-12">
                  <validate-field
                    title="Enable 2FA"
                    field="code"
                    :custom-errors="customErrors.googleSecretCode"
                    :validations="$v.form.googleSecretCode"
                    placeholder="Code"
                  />
                  <p class="mb-0">Enter the 6-digit code in authenticator</p>
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    :disabled="twoFactorAuthLoading"
                    class="btn btn-success waves-effect"
                  >
                    <span
                      v-if="twoFactorAuthLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span v-else>Confirm</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

// modules
import { required } from "vuelidate/lib/validators";

// components
import validateField from "@/components/ValidateField.vue";
import CopyButton from "../global/CopyButton.vue";

function validateCodeLength() {
  return this.form.googleSecretCode.length === 6;
}

export default {
  components: { validateField, CopyButton },
  data() {
    return {
      form: {
        googleSecretCode: "",
      },
      customErrors: {
        googleSecretCode: {
          validateCodeLength: "The code must contain 6 digits",
        },
      },
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      googleSecretCode: {
        required,
        validateCodeLength,
      },
    },
  },

  computed: {
    qrCode() {
      return this.profileStore.qrCode;
    },
    qrCodeloading() {
      return this.profileStore.qrCodeLoading;
    },
    twoFactorAuthLoading() {
      return this.profileStore.twoFactorAuthLoading;
    },
    isTwofactorAuthActivate() {
      return this.profileStore.profile?.two_fa_is_active;
    },
  },

  mounted() {
    this.profileStore.getTwoFactorAuthQrCode();
    this.profileStore.getProfile();
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.isTwofactorAuthActivate) {
          await this.profileStore.postTwoFactorAuthDeactivate(
            this.form.googleSecretCode
          );
        } else {
          await this.profileStore.postTwoFactorAuthActivate(
            this.form.googleSecretCode
          );
        }
      }
    },
  },
};
</script>

<style>
.qr_container {
  width: 100%;
  max-width: 17.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .input_l {
    font-size: 12px !important;
  }
}
</style>
