<template>
  <form-page>
    <template #header>
      <h4 class="card-title">Change Password</h4>
    </template>

    <form
      @submit.prevent="submit"
      name="myform"
      class="change_password_validate"
    >
      <validate-field
        title="Password"
        field="password"
        :custom-errors="customErrors.password"
        :validations="$v.form.password"
      >
        <input
          v-model="form.password"
          id="password"
          type="password"
          name="password"
          class="form-control"
          placeholder="Enter new password"
          ref="password"
        />
        <toggle-password @is-password-show="togglePasswordValue" />
      </validate-field>

      <validate-field
        title="Confirm Password"
        field="confirm_password"
        :custom-errors="customErrors.confirmPassword"
        :validations="$v.form.confirmPassword"
      >
        <input
          v-model="form.confirmPassword"
          id="confirm_password"
          type="password"
          name="password"
          class="form-control"
          placeholder="Enter confirm password"
          ref="confirmPassword"
        />
        <toggle-password @is-password-show="toggleConfirmPasswordValue" />
      </validate-field>

      <div class="text-center mt-4">
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-success btn-block"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Change</span>
        </button>
      </div>
    </form>
  </form-page>
</template>

<script>
// stores
import { useAuthStore } from "../../stores/auth-store";

// modules
import { required, sameAs } from "vuelidate/lib/validators";

// components
import formPage from "@/components/FormPage.vue";
import validateField from "@/components/ValidateField.vue";
import togglePassword from "../../components/global/TogglePassword.vue";

export default {
  components: { validateField, formPage, togglePassword },

  data() {
    return {
      form: {
        email: "",
        token: "",
        password: "",
        confirmPassword: "",
      },
      customErrors: {
        password: { required: "Please provide a password" },
        confirmPassword: {
          required: "Please provide a password",
          sameAsPassword: "Password do not match",
        },
      },
      authStore: useAuthStore(),
    };
  },

  validations: {
    form: {
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },

  computed: {
    loading() {
      return this.authStore.loading;
    },
  },

  mounted() {
    this.getQueryData();
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.authStore.postPasswordChange(
          this.form.email,
          this.form.token,
          this.form.password,
          this.form.confirmPassword
        );
      }
    },

    getQueryData() {
      const router = this.$route.query;
      this.form.email = router.email;
      this.form.token = router.token;
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },

    toggleConfirmPasswordValue(val) {
      const password = this.$refs.confirmPassword;
      password.type = val ? "text" : "password";
    },
  },
};
</script>
