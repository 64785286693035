<template>
  <div class="card">
    <div class="card-header border-0 py-0">
      <h4 class="card-title">Follow</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          v-for="(crypto, index) in cryptos"
          :key="index"
          class="col-xl-12 col-lg-6 col-xxl-6"
        >
          <div class="widget-card">
            <div class="d-flex justify-content-between align-items-center">
              <div class="widget-stat">
                <div class="coin-title">
                  <span
                    ><i :class="'cc ' + crypto.symbol.toUpperCase()"></i
                  ></span>
                  <h5 class="d-inline-block ml-2 mb-3">
                    {{ crypto.name }} <span>(24h)</span>
                  </h5>
                </div>
                <h4>
                  USD {{ crypto.current_price }}
                  <span
                    :class="
                      'badge ml-2 ' +
                      (crypto.price_change_percentage_24h >= 0
                        ? 'badge-success'
                        : 'badge-danger')
                    "
                  >
                    {{ crypto.price_change_percentage_24h.toFixed(2) }}%
                  </span>
                </h4>
              </div>
              <div :id="'chart-' + crypto.id"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      cryptos: [],
    };
  },
  methods: {
    async fetchFollowData() {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/coins/markets",
          {
            params: {
              vs_currency: "usd",
              ids: "bitcoin,litecoin,ripple,dash",
            },
          }
        );
        this.cryptos = response.data;
        this.cryptos.forEach((crypto) => this.initChart(crypto));
      } catch (error) {
        console.error("Error fetching follow data:", error);
      }
    },
    initChart(crypto) {
      const options = {
        chart: {
          type: "line",
          height: 60,
          width: 160,
          sparkline: { enabled: true },
        },
        series: [
          {
            name: crypto.name,
            data: crypto.sparkline_in_7d?.price,
          },
        ],
        stroke: { curve: "smooth", width: 2, colors: ["#7391FF"] },
        tooltip: { enabled: false },
        yaxis: { show: false },
        xaxis: {
          categories: Array.from(
            { length: crypto.sparkline_in_7d?.price.length },
            (_, i) => i + 1
          ),
          labels: { show: false },
        },
      };

      // this.renderChart(options);
    },
    renderChart(options) {
      const chartOrigin = document.querySelector(`#chart-${crypto.id}`);

      // untuk mencegah error ketika id chart-${crypto.id} tidak ada
      if (chartOrigin) {
        const chart = new ApexCharts(
          document.querySelector(`#chart-${crypto.id}`),
          options
        );
        chart.render();
      }
    },
  },
  mounted() {
    this.fetchFollowData();
  },
};
</script>
