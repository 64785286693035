<template>
  <form-page>
    <template #header>
      <h4 class="card-title">Email Verification</h4>
    </template>

    <form
      @submit.prevent="submit"
      name="myform"
      class="email_verification_validate"
    >
      <validate-field
        title="Email"
        field="email"
        :validations="$v.form.email"
        placeholder="hello@wavedex.io"
      />

      <div class="text-center">
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-success btn-block"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Submit</span>
        </button>
      </div>
    </form>
    <div class="new-account mt-3">
      <p class="mb-1">
        Have you verified?
        <router-link class="text-primary" to="sign-in">Sign in</router-link>
      </p>
    </div>
  </form-page>
</template>

<script>
// stores
import { useAuthStore } from "../../stores/auth-store";

// modules
import { required, email } from "vuelidate/lib/validators";

// components
import formPage from "@/components/FormPage.vue";
import validateField from "@/components/ValidateField.vue";

export default {
  components: { formPage, validateField },

  data() {
    return {
      form: {
        email: "",
        reCaptcha: "",
      },
      authStore: useAuthStore(),
    };
  },

  validations: {
    form: {
      email: { required, email },
    },
  },

  computed: {
    loading() {
      return this.authStore.loading;
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.authStore.postRequestEmailVerification(
          this.form.email,
          this.form.reCaptcha
        );
      }
    },
  },
};
</script>
