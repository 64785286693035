<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header border-0">
          <h4 class="card-title">Recent Subscriptions</h4>
        </div>
        <div class="card-body pt-0">
          <div v-if="loading" class="chart-stat py-5 text-center">
            <span
              class="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
          </div>

          <div v-if="history" class="transaction-table">
            <div class="table-responsive">
              <table
                class="table mb-0 table-responsive-sm text-center text-nowrap"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Payment Status</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="subscription of history" :key="subscription.id">
                    <td>
                      <span class="buy-thumb bg-primary mx-auto">{{
                        subscription.id
                      }}</span>
                    </td>
                    <td>
                      {{
                        moment(subscription.date_in).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      }}
                    </td>
                    <td>
                      {{
                        moment(subscription.date_out).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      }}
                    </td>
                    <td>{{ subscription.stype }}</td>
                    <td class="text-success">{{ subscription.amount }}</td>
                    <td>
                      <span v-if="subscription.paid" class="text-success"
                        >Paid</span
                      >
                      <span v-else class="text-danger">Unpaid</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <PaginationButtons
              :isLoading="isLoading"
              :isBackLoading="isBackLoading"
              :canGoBack="page > 1"
              :page="page"
              :canLoadMore="history.length >= rows"
              @go-back="handleGoBack"
              @load-more="handleLoadMore"
            />
          </div>

          <div v-else class="chart-stat py-5 text-center">
            <i class="fa fa-search fa-3x mb-3" aria-hidden="true"></i>
            <h4>No data have been recorded</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// modules
import moment from "moment";

// stores
import { useSubscriptionStore } from "../../stores/subscription-store";

// components;
import PaginationButtons from "../global/PaginationButtons.vue";

export default {
  components: { PaginationButtons },
  data() {
    return {
      subscriptionStore: useSubscriptionStore(),
      moment,
      page: 1,
      rows: 5,
      order: "desc",
      isLoading: false,
      isBackLoading: false,
    };
  },

  methods: {
    async handleLoadMore() {
      this.isloading = true;
      this.page += 1;
      await this.subscriptionStore.getSubscriptionHistory(
        this.page,
        this.rows,
        this.order
      );
      this.isloading = false;
    },

    async handleGoBack() {
      if (this.page > 1) {
        this.isBackLoading = true;
        this.page -= 1;
        await this.subscriptionStore.getSubscriptionHistory(
          this.page,
          this.rows,
          this.order
        );
        this.isBackLoading = false;
      }
    },
  },

  computed: {
    history() {
      return this.subscriptionStore.subscriptionHistory;
    },
    loading() {
      return this.subscriptionStore.historyLoading;
    },
  },

  mounted() {
    this.subscriptionStore.getSubscriptionHistory(
      this.page,
      this.rows,
      this.order
    );
  },
};
</script>
