<template>
  <main-wrapper :is-loading="isLoading" class="container_wrap">
    <div class="item_wrapper">
      <landing-header />
    </div>

    <!-- MAIN -->
    <router-view name="landingIntro" />
    <about />
    <router-view name="features" />
    <Roadmap />
    <News />
    <landing-footer />
  </main-wrapper>
</template>

<script>
import mainWrapper from "@/components/MainWrapper.vue";
import landingHeader from "@/components/landing/Header.vue";
import landingFooter from "@/components/landing/Footer.vue";
import about from "../contents/landing/About.vue";
import Roadmap from "../contents/landing/Roadmap.vue";
import News from "../contents/landing/News.vue";
import app from "@/App.vue";

export default {
  extends: app,
  components: {
    mainWrapper,
    landingHeader,
    about,
    Roadmap,
    News,
    landingFooter,
  },
};
</script>

<style scoped>
.container_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item_wrapper {
  width: 100%;
}

@media only screen and (max-width: 1078px) {
  .item_wrapper {
    width: 95%;
  }
}
</style>
