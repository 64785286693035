import { ref, computed } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_API } from "../applibs/http-common";
import { toast } from "../helpers/toast";

export const useSubscriptionStore = defineStore("subscription", () => {
  // data state
  const subscriptionHistory = ref(null);
  const subscriptionDetail = ref({});

  // loading state
  const createLoading = ref(false);
  const historyLoading = ref(false);
  const detailLoading = ref(false);

  async function postSubscriptionCreate(subtype) {
    try {
      createLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/subscriptions/create-new`,
        data: {
          subtype,
        },
      });

      if (res.data.success) {
        if (res.data.msg) {
          toast.success(res.data.msg);
        } else {
          toast.success("Subscription successful");
        }

        // berfungsi untuk refresh data
        getSubscriptionHistory(1, 5, "desc");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      createLoading.value = false;
    }
  }

  async function getSubscriptionHistory(page, rows, order) {
    try {
      historyLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/subscriptions?page=${page}&rows=${rows}&order=${order}`,
      });

      if (res.data.success && res.data.data.datas.length > 0) {
        subscriptionHistory.value = res.data.data.datas;
      }
    } catch (err) {
      console.error(err);
    } finally {
      historyLoading.value = false;
    }
  }

  const isSubscriptionActive = computed(() => {
    if (!subscriptionHistory.value) return false;

    const now = new Date();
    return subscriptionHistory.value.some(
      (sub) => new Date(sub.date_out) > now
    );
  });

  return {
    subscriptionHistory,
    subscriptionDetail,
    createLoading,
    historyLoading,
    detailLoading,
    getSubscriptionHistory,
    postSubscriptionCreate,
    isSubscriptionActive,
  };
});
