import axios from "axios";

export const HTTP = axios.create({
  baseURL: import.meta.env.BASE_API,
  headers: {
    Authorization: "Bearer {token}",
  },
});

export const BASE_API = import.meta.env.VITE_BASE_API;
export const NEWS_API = import.meta.env.VITE_NEWS_API;
export const NEWS_API_KEY = import.meta.env.VITE_NEWS_API_KEY;
export const BOT_JO = import.meta.env.VITE_BOT_JO;
export const BASE_API_V1 = import.meta.env.VITE_BASE_API_V1;
