<template>
  <div class="row">
    <div class="col-xl-12">
      <KycUpdateForm />
    </div>
  </div>
</template>

<script>
import KycUpdateForm from "../../../components/kyc/KycUpdateForm.vue";

export default {
  components: { KycUpdateForm },
};
</script>
