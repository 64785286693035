<template>
  <content-body>
    <div class="row">
      <div class="col-lg-12">
        <SubscriptionsForm />
      </div>
    </div>
    <div>
      <SubscriptionsHistory />
    </div>
  </content-body>
</template>

<script>
// components
import contentBody from "@/components/dashboard/ContentBody.vue";
import app from "@/App.vue";
import SubscriptionsForm from "../../components/subscriptions/SubscriptionsForm.vue";
import SubscriptionsHistory from "../../components/subscriptions/SubscriptionsHistory.vue";

export default {
  extends: app,
  components: {
    contentBody,
    SubscriptionsForm,
    SubscriptionsHistory,
  },
};
</script>
