<template>
  <content-body>
    <div class="row mg_container">
      <div class="col-xl-5 col-lg-5 col-md-12">
        <div class="card">
          <h4 class="card-title">Data Migration</h4>
          <div class="card-body">
            <migration-form />
          </div>
        </div>
      </div>
    </div>
  </content-body>
</template>

<script>
import contentBody from "@/components/dashboard/ContentBody.vue";
import MigrationForm from "@/components/migration/MigrationForm.vue";
import app from "@/App.vue";

export default {
  extends: app,
  components: {
    contentBody,
    MigrationForm
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.mg_container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40px;
}

@media only screen and (max-width: 500px) {
  .mg_container {
    margin-left: 0;
  }
}
@media only screen and (max-width: 469px) {
  .col-xl-7 img {
    width: 300px;
  }
}
</style>
