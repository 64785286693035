<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div class="card-body text-center">
            <h2>Perform a transaction by scanning the QR code below</h2>
            <div class="my-4">
              <img
                :src="data?.qrcode_url"
                alt="QR Code for transaction"
                class="img-fluid"
              />
            </div>
            <address class="mb-3">
              <strong>Address</strong><br />
              {{ data?.address }}
            </address>
            <a
              :href="data?.checkout_url"
              target="_blank"
              class="btn btn-primary"
              >Checkout</a
            >
          </div>
        </div>
      </div>

      <div class="col-md-5 mx-auto">
        <div class="card mb-4">
          <div class="card-body text-center">
            <div
              v-if="timeRemaining <= 0 && !paymentStatus"
              class="chart-stat text-danger font-weight-bold"
            >
              Timed Out
            </div>
            <div
              v-else-if="paymentStatus"
              class="chart-stat text-success font-weight-bold"
            >
              Payment Completed
            </div>
            <div v-else class="chart-stat py-4">
              <h2 class="mb-3">OFFER ENDS IN</h2>
              <div class="d-flex justify-content-center">
                <div class="time-box">
                  <h3 class="text-danger">{{ hours }}</h3>
                  <p class="mb-0">HOURS</p>
                </div>
                <span class="mx-2" style="font-size: 2rem">:</span>
                <div class="time-box">
                  <h3 class="text-danger">{{ minutes }}</h3>
                  <p class="mb-0">MINUTES</p>
                </div>
                <span class="mx-2" style="font-size: 2rem">:</span>
                <div class="time-box">
                  <h3 class="text-danger">{{ seconds }}</h3>
                  <p class="mb-0">SECONDS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header border-0">
            <h4 class="card-title">Coinpayment summary</h4>
          </div>
          <div class="card-body pt-0">
            <div class="transaction-table">
              <div class="table-responsive">
                <table
                  class="table mb-0 table-responsive-sm text-center text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>TXN ID</th>
                      <th>Amount</th>
                      <th>Network</th>
                      <th>Payment Status</th>
                      <th>Status URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ data?.txn_id }}
                      </td>
                      <td class="text-success">{{ data?.amount }}</td>
                      <td>{{ network }}</td>
                      <td>
                        <span v-if="paymentStatus === true" class="text-success"
                          >Paid</span
                        >
                        <span v-else class="text-danger">Unpaid</span>
                      </td>
                      <td>
                        <a
                          :href="data?.status_url"
                          target="_blank"
                          class="btn btn-secondary py-1"
                        >
                          Check Status
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    network: String,
    dateUpdate: String,
    paymentStatus: Boolean,
    id: String,
  },

  data() {
    return {
      countdownInterval: null,
      timeRemaining: null,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },

  mounted() {
    this.updateCountdown();
    this.countdownInterval = setInterval(this.updateCountdown, 1000);
  },

  methods: {
    updateCountdown() {
      const currentTime = new Date().getTime();
      const startTime = new Date(this.dateUpdate).getTime();
      this.timeRemaining = startTime + 3 * 60 * 60 * 1000 - currentTime;

      if (this.timeRemaining <= 0) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
      } else {
        this.hours = Math.floor(this.timeRemaining / (1000 * 60 * 60));
        this.minutes = Math.floor(
          (this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((this.timeRemaining % (1000 * 60)) / 1000);
      }
    },
  },
};
</script>
