<template>
  <div id="main-wrapper" :class="{ show: !isLoading }" class="bg">
    <slot />
  </div>
</template>

<script>
export default {
  props: ["isLoading"],
};
</script>

<style scoped>
.bg {
  background-image: url("../../images/bg-dashboard.webp");
  background-size: cover;
  background-attachment: fixed;
}
</style>
