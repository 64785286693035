<template>
  <div class="card-body pt-0">
    <div class="transaction-table">
      <div class="table-responsive">
        <table class="table mb-0 table-responsive-sm">
          <tbody>
            <tr>
              <td>LABEL</td>
              <td>BALANCE</td>
              <td>UPDATE AT</td>
            </tr>
            <tr v-for="item in balances" :key="item.id">
              <td>
                <span v-if="item.label === 'A'" class="badge badge-success p-2">
                  [ A ] SECURITY
                </span>
                <span v-if="item.label === 'B'" class="badge badge-danger p-2">
                  [ B ] BONUS
                </span>
                <span v-if="item.label === 'C'" class="badge badge-info p-2">
                  [ C ] ROI
                </span>
              </td>
              <td>{{ item.value }}</td>
              <td>
                {{ moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
// modules
import moment from "moment";
import { computed, onMounted } from "vue";
import { useProfileStore } from "../../stores/profile-store";

const profileStore = useProfileStore();
const balances = computed(() => profileStore.balances);

onMounted(() => {
  profileStore.getBalance();
});
</script>
