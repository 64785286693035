<template>
  <content-body>
    <div
      v-if="loading"
      class="spinner-border"
      role="status"
      aria-hidden="true"
    ></div>
    <paymentDetail
      v-else
      :data="depositDetail.coinPaymentDetail"
      :network="depositDetail.network"
      :dateUpdate="depositDetail.dateUpdate"
      :paymentStatus="depositDetail.paymentStatus"
      :id="id"
    />
  </content-body>
</template>

<script>
// stores
import { useDepositStore } from "../../stores/deposit-store";

// components
import contentBody from "@/components/dashboard/ContentBody.vue";
import paymentDetail from "../../components/global/PaymentDetail.vue";

import app from "@/App.vue";

export default {
  extends: app,

  components: {
    contentBody,
    paymentDetail,
  },

  data() {
    return {
      depositStore: useDepositStore(),
      id: null,
    };
  },

  computed: {
    depositDetail() {
      return this.depositStore.depositDetail;
    },
    loading() {
      return this.depositStore.detailLoading;
    },
  },

  mounted() {
    this.getId();
    this.depositStore.getDepositDetail(this.id);
  },

  methods: {
    getId() {
      const route = this.$route;
      const id = route.params.id;
      this.id = id;
    },
  },
};
</script>
