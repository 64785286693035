<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Change Avatar</h4>
    </div>
    <div class="card-body">
      <form @submit.prevent="submit" name="avatar_change" class="avatar_change">
        <div class="form-row">
          <div class="form-group col-xl-12">
            <div class="media align-items-center mb-3">
              <img
                v-if="profile?.orang_avatar_file_url"
                class="mr-3 rounded-circle mr-0 mr-sm-3 bg-light"
                :src="profile?.orang_avatar_file_url"
                width="45"
                height="45"
                alt=""
              />
              <div v-else class="profile_log">
                <div class="user">
                  <span class="thumb"><i class="la la-user"></i></span>
                </div>
              </div>
              <div class="media-body">
                <div>
                  <h4 v-if="profile?.orang_name" class="mb-0">
                    {{ profile?.orang_name }}
                  </h4>
                  <h4 v-else class="mb-0">{{ profile?.orang_nickname }}</h4>
                </div>
                <p class="mb-0">Max file size is 5 MB</p>
              </div>
            </div>

            <div v-if="form.avatarImage" class="d-flex justify-content-center">
              <validate-field
                :custom-errors="customErrors.image"
                :validations="$v.form.avatarImage"
              >
                <div
                  class="chart-stat d-flex justify-content-center align-items-center"
                  style="width: 250px; height: 250px"
                >
                  <div class="image_container">
                    <img
                      :src="imageDetails.avatarImagePreview"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </validate-field>
            </div>

            <div
              class="file-upload-wrapper"
              :data-text="imageDetails.avatarImageName"
            >
              <input
                @change="imageUploadHandler($event)"
                name="avatar_file_upload"
                type="file"
                class="avatar_file_upload"
              />
            </div>
          </div>
          <div class="col-12">
            <button
              type="submit"
              :disabled="loading"
              class="btn btn-success waves-effect"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

// modules
import { required } from "vuelidate/lib/validators";

// components
import copyButton from "../../components/global/CopyButton.vue";
import validateField from "@/components/ValidateField.vue";

export default {
  components: { copyButton, validateField },

  props: {
    profile: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      form: {
        avatarImage: null,
      },
      imageDetails: {
        avatarImagePreview: "",
        avatarImageName: "Avatar.jpg",
      },
      customErrors: {
        image: {
          required: "You must upload an image",
        },
      },
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      avatarImage: { required },
    },
  },

  computed: {
    loading() {
      return this.profileStore.avatarUpdateLoading;
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.profileStore.postAvatarUpdate(this.form.avatarImage);
      }
    },

    imageUploadHandler(event) {
      const file = event.target.files[0];
      const validTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5 MB

      if (file) {
        if (!validTypes.includes(file.type)) {
          this.$toast.warning(
            "Invalid file format. Only JPEG and PNG are allowed."
          );
          return;
        }

        if (file.size > maxSize) {
          this.$toast.warning(
            "File size exceeds 5MB. Please upload a smaller file."
          );
          return;
        }

        this.form.avatarImage = file;
        this.imageDetails.avatarImageName = file.name;

        this.previewImageHandler(file);
      }
    },

    previewImageHandler(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageDetails.avatarImagePreview = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
.image_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 100%;
  overflow: hidden;
  background-color: #f8f9fa;
}
</style>
