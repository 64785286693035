import Cookies from "js-cookie";

import demo from "./pages/Demo.vue";

// landing
import landing from "./pages/Landing.vue";

// dashboard
import dashboard from "./pages/Dashboard.vue";

// auth
import signIn from "./pages/auth/Signin.vue";
import signUp from "./pages/auth/Signup.vue";
import resetPassword from "./pages/auth/ResetPassword.vue";
import changePassword from "./pages/auth/ChangePassword.vue";
import requestEmailVerification from "./pages/auth/RequestEmailVerification.vue";
import emailVerification from "./pages/auth/EmailVerification.vue";
import lock from "./pages/auth/Lock.vue";
import oneTimePassword1 from "./pages/auth/OneTimePassword1.vue";
import twoFactorAuth from "./pages/auth/TwoFactorAuth.vue";
import emailTwoFactorAuth from "./pages/auth/EmailTwoFactorAuth.vue";

import migration from "./contents/dashboard/Migration.vue";
import transfer from "./contents/dashboard/Transfer.vue";
import deposit from "./contents/dashboard/Deposit.vue";
import withdraw from "./contents/dashboard/withdraw.vue";
import depositDetail from "./contents/dashboard/DepositDetail.vue";
import subscriptions from "./contents/dashboard/Subscriptions.vue";
import home from "./contents/dashboard/Home.vue";
import dashboardPageTitle from "./components/dashboard/PageTitle.vue";
import addBankAcc from "./contents/dashboard/AddBankAcc.vue";
import accounts from "./contents/dashboard/Accounts.vue";
import history from "./contents/dashboard/History.vue";
import termCondition from "./contents/landing/TermCondition.vue";
import privacyPolicy from "./contents/landing/PrivacyPolicy.vue";
import landingIntro from "./contents/landing/Intro.vue";
import market from "./contents/landing/Market.vue";
import features from "./contents/landing/Features.vue";
import portfolio from "./contents/landing/Portfolio.vue";
import testimonial from "./contents/landing/Testimonial.vue";
import contact from "./contents/landing/Contact.vue";
import landingPageTitle from "./components/landing/PageTitle.vue";
import about from "./contents/landing/About.vue";
import settings from "./contents/dashboard/settings/Settings.vue";
import profile from "./contents/dashboard/settings/Profile.vue";
import withdrawAddress from "./contents/dashboard/settings/WithdrawAddress.vue";
import kycUpdate from "./contents/dashboard/settings/KycUpdate.vue";
import security from "./contents/dashboard/settings/Security.vue";

import preferences from "./contents/dashboard/settings/Preferences.vue";
import linkedAccount from "./contents/dashboard/settings/LinkedAccount.vue";
import verifyStep1 from "./contents/dashboard/VerifyStep1.vue";
import verifyStep2 from "./contents/dashboard/VerifyStep2.vue";
import verifyStep3 from "./contents/dashboard/VerifyStep3.vue";
import verifyStep4 from "./contents/dashboard/VerifyStep4.vue";
import verifyStep5 from "./contents/dashboard/VerifyStep5.vue";
import verifyStep6 from "./contents/dashboard/VerifyStep6.vue";
import addDebitCard from "./contents/dashboard/AddDebitCard.vue";
import demoIntro from "./contents/demo/Intro.vue";
import pages from "./contents/demo/Pages.vue";
import support from "./contents/demo/Support.vue";

function checkLoginStatus() {
  return Cookies.get("jwt") ? true : false;
}

export default [
  { path: "*", redirect: "/" },

  {
    path: "/",
    component: landing,
    children: [
      {
        path: "",
        components: {
          landingIntro,
          market,
          features,
          portfolio,
          testimonial,
          contact,
        },
      },
      {
        path: "about",
        meta: { title: "About Us" },
        components: { pageTitle: landingPageTitle, about },
      },
      {
        path: "terms-of-service",
        meta: { title: "Terms & Condition" },
        components: { pageTitle: landingPageTitle, termCondition },
      },
      {
        path: "privacy-policy",
        meta: { title: "Privacy Policy" },
        components: { pageTitle: landingPageTitle, privacyPolicy },
      },
    ],
  },

  // {
  //   path: "/demo",
  //   component: demo,
  //   children: [
  //     {
  //       path: "",
  //       meta: { title: "Demo" },
  //       components: { demoIntro, pages, support },
  //     },
  //   ],
  // },

  {
    path: "/auth/sign-in",
    meta: { title: "Sign In" },
    component: signIn,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/sign-up",
    meta: { title: "Sign Up" },
    component: signUp,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/reset-password",
    meta: { title: "Reset Password" },
    component: resetPassword,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/passchange-form",
    meta: { title: "Change Password" },
    component: changePassword,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/request-email-verification",
    meta: { title: "Request Email Verification" },
    component: requestEmailVerification,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/email-verification",
    meta: { title: "Email Verification" },
    component: emailVerification,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/otp-1",
    meta: { title: "OTP Number" },
    component: oneTimePassword1,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/two-factor-auth",
    meta: { title: "Two Factor Auth" },
    component: twoFactorAuth,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/email-two-factor-auth",
    meta: { title: "Email Two Factor Auth" },
    component: emailTwoFactorAuth,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/auth/lock",
    meta: { title: "Locked" },
    component: lock,
    beforeEnter: (to, from, next) => {
      if (checkLoginStatus()) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },

  {
    path: "/dashboard",
    component: dashboard,
    beforeEnter: (to, from, next) => {
      if (!checkLoginStatus()) {
        next("/");
      } else {
        next();
      }
    },
    children: [
      { path: "", redirect: "home" },
      {
        path: "home",
        meta: { title: "Dashboard" },
        components: { default: home, dashboardPageTitle },
      },
      {
        path: "transfer",
        meta: { title: "Transfer" },
        components: { default: transfer, dashboardPageTitle },
      },
      {
        path: "deposit",
        meta: { title: "Deposit" },
        components: { default: deposit, dashboardPageTitle },
      },
      // {
      //   path: "withdraw",
      //   meta: { title: "Withdraw" },
      //   components: { default: withdraw, dashboardPageTitle },
      // },
      {
        path: "deposit/detail/:id",
        meta: { title: "Deposit Detail" },
        components: { default: depositDetail, dashboardPageTitle },
      },
      {
        path: "subscriptions",
        meta: { title: "Subscriptions" },
        components: { default: subscriptions, dashboardPageTitle },
      },
      {
        path: "accounts",
        meta: { title: "Accounts" },
        components: { default: accounts, dashboardPageTitle },
      },
      {
        path: "history",
        meta: { title: "History" },
        components: { default: history, dashboardPageTitle },
      },
      {
        path: "add-bank-acc",
        meta: { title: "Add Bank Account" },
        component: addBankAcc,
      },
      // {
      //   path: "add-debit-card",
      //   meta: { title: "Add Debit Card" },
      //   component: addDebitCard,
      // },
      // {
      //   path: "verify-step-1",
      //   meta: { title: "Verify ID" },
      //   component: verifyStep1,
      // },
      // {
      //   path: "verify-step-2",
      //   meta: { title: "Upload ID" },
      //   component: verifyStep2,
      // },
      // {
      //   path: "verify-step-3",
      //   meta: { title: "ID Verifying..." },
      //   component: verifyStep3,
      // },
      // {
      //   path: "verify-step-4",
      //   meta: { title: "ID Verified" },
      //   component: verifyStep4,
      // },
      // {
      //   path: "verify-step-5",
      //   meta: { title: "Recommendation" },
      //   component: verifyStep5,
      // },
      // {
      //   path: "verify-step-6",
      //   meta: { title: "Success" },
      //   component: verifyStep6,
      // },

      {
        path: "settings",
        components: { default: settings, dashboardPageTitle },
        children: [
          { path: "", meta: { title: "Edit Profile" }, component: profile },
          {
            path: "kyc-update",
            meta: { title: "KYC Update" },
            component: kycUpdate,
          },
          {
            path: "withdraw-address",
            meta: { title: "Withdraw Address" },
            component: withdrawAddress,
          },
          // {
          //   path: "preferences",
          //   meta: { title: "Preferences" },
          //   component: preferences,
          // },
          {
            path: "security",
            meta: { title: "Security" },
            component: security,
          },
          // {
          //   path: "linked-account",
          //   meta: { title: "Linked Account" },
          //   component: linkedAccount,
          // },
        ],
      },
      {
        path: "migration",
        meta: { title: "Migration" },
        components: { default: migration, dashboardPageTitle },
      },
    ],
  },
];
