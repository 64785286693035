<template>
  <div class="intro position-relative" id="intro">
    <div class="container_wrap">
      <div class="row align-items-center justify-content-between">
        <div class="col-xl-7 col-md-6">
          <div class="intro-content_i">
            <div class="intro_head">
              <h2>INTRODUCTION</h2>
              <h3>WAVEDEX</h3>
            </div>

            <div class="mt-4 intro_desc">
              <p>
                Trading bots can execute trades automatically based on
                pre-defined criteria, ensuring that trades are executed without
                emotions, 24/7. Emotions like fear and greed can lead to
                impulsive decisions in manual trading, which bots avoid.
              </p>
              <p class="mt-4">
                The intuitively designed user interface, characterized by its
                user-friendly and minimalist approach, guarantees ease of use
                for individuals at all levels. With a straightforward
                push-button functionality, users can effortlessly initiate and
                halt trades within the application, making the trading process
                both simple and enjoyable.
              </p>
            </div>

            <div class="user_count-wrap">
              <div class="user_count">
                <h2>5509</h2>
                <h3>User Count</h3>
                <button>Read more...</button>
              </div>
              <div class="sosmed">
                <div class="sosmed_item">
                  <a
                    href="https://www.facebook.com/WaveDexOfficial"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffff"
                        d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"
                      />
                    </svg>
                  </a>
                </div>
                <div class="sosmed_item">
                  <a href="https://www.instagram.com/wavedex" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffff"
                        d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                      />
                    </svg>
                  </a>
                </div>
                <div class="sosmed_item">
                  <a href="https://twitter.com/WaveDexOfficial" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ffff"
                        d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.2 4.2 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.52 8.52 0 0 1-5.33 1.84q-.51 0-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23"
                      />
                    </svg>
                  </a>
                </div>
                <div class="sosmed_item">
                  <a href="https://www.tiktok.com/@wavedex" target="_blank"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
                      /></svg
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currencyForm from "@/components/CurrencyForm.vue";

export default {
  components: { currencyForm },
};
</script>

<style scoped>
.intro {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/images/intro-bg.webp");
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.container_wrap {
  width: 80%;
  max-width: 110rem;
}
.intro_head {
  margin-top: 50px;
}
.intro_head h2 {
  color: var(--text-base);
  font-size: 60px;
  font-weight: bold;
  margin: 0;
}
.intro_head h3 {
  color: var(--text-base);
}
.intro_desc p {
  font-weight: 400;
}
.user_count {
  margin-top: 40px;
}
.user_count h2 {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
}
.user_count h3 {
  font-size: 16px;
  font-weight: bold;
}
.user_count button {
  border: 1px solid #448ac4;
  background: none;
  color: #448ac4;
  padding: 4px 20px;
  margin-top: 20px;
}
.user_count-wrap {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.sosmed {
  display: flex;
  gap: 10px;
}
.sosmed_item {
  border: 1px solid #ffff;
  border-radius: 100%;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 538px) {
  .intro_head h2 {
    font-size: 20px;
  }
  .intro_head h3 {
    font-size: 16px;
  }
  .intro_desc p {
    font-size: 14px;
  }
  .user_count h2 {
    font-size: 20px;
  }
  .user_count h3 {
    font-size: 16px;
  }
  .user_count button {
    font-size: 14px;
  }
  .sosmed_item {
    width: 30px;
    height: 30px;
  }
}
</style>
