<template>
  <div class="page_title">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div class="page_title-content">
            <p>
              Welcome Back,<span> {{ profileData?.orang_nickname }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useProfileStore } from "../../stores/profile-store";

const profileStore = useProfileStore();
const profileData = computed(() => profileStore.profile);
</script>
