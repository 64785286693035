import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_API } from "../applibs/http-common";
import { toast } from "../helpers/toast";

export const useTransferStore = defineStore("transfer", () => {
  const createLoading = ref(false);
  const historyLoading = ref(false);
  const transferHistory = ref(null);

  async function postTransferCreate(to_address, amount) {
    try {
      createLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/transfer/create`,
        data: {
          to_address,
          amount,
        },
      });

      if (res.data.success) {
        toast.success(res.data.msg);

        // berfungsi untuk refresh data
        // getTransactionHistory;
        window.location.href = "/dashboard/transfer";
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      createLoading.value = false;
    }
  }

  async function getTransactionHistory() {
    try {
      historyLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/transfer/history`,
      });

      if (res.data.success && res.data.data.datas.length > 0) {
        transferHistory.value = res.data.data.datas;
      }
    } catch (err) {
      console.error(err);
    } finally {
      historyLoading.value = false;
    }
  }

  return {
    createLoading,
    historyLoading,
    transferHistory,
    postTransferCreate,
    getTransactionHistory,
  };
});
