<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <i
            class="las la-times-circle close_button text-danger"
            @click="$emit('close')"
          ></i>
        </div>

        <div class="modal-body">
          <div class="mb-3 text-center">
            <h3>Subscription Package</h3>
            <strong>{{ subsType }}</strong>
          </div>

          <div class="form-group mb-3">
            <label class="mr-sm-2 ml-1">Price</label>
            <div class="input-group">
              <input
                :value="price + ' USD'"
                type="text"
                name="balance"
                class="form-control"
                placeholder="Subscriptions price"
                disabled
              />
            </div>
          </div>

          <div class="form-group mb-0">
            <label class="mr-sm-2 ml-1">Balance</label>
            <div class="input-group">
              <input
                :value="networkBalance + ' WUSD'"
                type="text"
                name="balance"
                class="form-control"
                placeholder="1000 WUSD"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            @click="$emit('close')"
            class="modal-default-button border-danger text-danger"
          >
            Cancel
          </button>
          <button
            class="modal-default-button"
            @click="submit"
            :disabled="!subsPriceHandler || loading"
          >
            <span v-if="!loading">Submit</span>
            <span v-else>Loading..</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// stores
import { useSubscriptionStore } from "../../stores/subscription-store";
import { useProfileStore } from "../../stores/profile-store";

export default {
  props: {
    show: Boolean,
    isSuccessUpdate: Boolean,
    subsType: { type: String },
  },

  data() {
    return {
      subscriptionStore: useSubscriptionStore(),
      profileStore: useProfileStore(),
      price: null,
    };
  },

  computed: {
    loading() {
      return this.subscriptionStore.createLoading;
    },

    networkBalance() {
      return this.profileStore.networkBalance.balance;
    },

    subsPriceHandler() {
      return this.networkBalance >= this.price;
    },
  },

  watch: {
    subsType: {
      immediate: true,
      handler(newVal) {
        this.getSubsPrice(newVal);
      },
    },
  },

  mounted() {
    this.profileStore.getNetworkBalance();
    this.getSubsPrice();
  },

  methods: {
    async submit() {
      await this.subscriptionStore.postSubscriptionCreate(this.subsType);
    },

    getSubsPrice() {
      this.price = 100;
      // if (this.subsType === "3 months") {
      //   this.price = 100;
      // }
      //  else if (this.subsType === "6 months") {
      //   this.price = 100;
      // } else if (this.subsType === "12 months") {
      //   this.price = 150;
      // }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  margin: auto;
  /* padding: 20px 30px; */
  background-color: #102886;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  position: relative;
  border: none;
  display: flex;
  justify-content: center;
}

.modal-header h3 {
  margin-top: 0;
  color: #bfc2c1;
  text-align: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-footer {
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-footer button:first-child {
  background: none;
  border: 1px solid #7b6fff;
  color: #7b6fff;
  border-radius: 5px;
  padding: 4px 10px;
}

.modal-footer button:last-child {
  background: #7b6fff;
  border: 1px solid #7b6fff;
  color: #bfc2c1;
  border-radius: 5px;
  padding: 4px 10px;
}

.close_button {
  position: absolute;
  top: 30%;
  right: 3%;
  cursor: pointer;
  font-size: 1.9rem;
}

button:disabled {
  filter: brightness(75%);
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.animation_s {
  display: flex;
  justify-content: center;
}
</style>
