<template>
  <div class="card acc_balance">
    <div class="card-header">
      <h4 class="card-title">Address</h4>
    </div>
    <div class="card-body">
      <div class="mb-4">
        <span>Address</span>
        <h3>{{ address }}</h3>
      </div>

      <button class="btn btn-primary btn-block" @click="copyToClipboard">
        Copy
      </button>
    </div>
  </div>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

import { toast } from "../../helpers/toast";

export default {
  data() {
    return {
      profileStore: useProfileStore(),
    };
  },

  computed: {
    address() {
      return this.profileStore.address;
    },
  },

  mounted() {
    this.profileStore.getAddress();
  },

  methods: {
    copyToClipboard() {
      const textToCopy = this.address;
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast.success("Address copied to clipboard!");
    },
  },
};
</script>
