<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mx-auto">AI BOT SUBSCRIPTIONS</h4>
      </div>

      <div class="card-body">
        <div class="mb-4 text-center">
          <p>
            Welcome to your Wavedex dashboard, where all your data insights come
            to life!
          </p>
          <p>
            To optimize your experience and access premium features, please
            select the $100 subscription bot for a three-month contract.
          </p>
          <strong>We are glad to be with you on this journey!</strong>
        </div>
        <div>
          <component
            :is="
              subscriptionStore.isSubscriptionActive
                ? 'ActiveSubscriptionNotice'
                : 'SubscriptionsCard'
            "
            :handleShowModal="handleShowModal"
          />
        </div>
      </div>
    </div>

    <ModalSubscriptions
      :subsType="subsType"
      :show="showModalSelect"
      @close="showModalSelect = false"
    />
  </div>
</template>

<script>
// components
import ModalSubscriptions from "../modals/ModalSubscriptions.vue";
import SubscriptionsCard from "./SubscriptionsCard.vue";

// stores
import { useSubscriptionStore } from "../../stores/subscription-store";
import ActiveSubscriptionNotice from "./ActiveSubscriptionNotice.vue";

export default {
  components: {
    SubscriptionsCard,
    ModalSubscriptions,
    ActiveSubscriptionNotice,
  },

  data() {
    return {
      subsType: null,
      showModalSelect: false,
      subscriptionStore: useSubscriptionStore(),
      page: 1,
      rows: 5,
      order: "desc",
    };
  },

  computed: {
    isSubscriptionActive() {
      return this.subscriptionStore.isSubscriptionActive;
    },
  },

  mounted() {
    this.subscriptionStore.getSubscriptionHistory(
      this.page,
      this.rows,
      this.order
    );
  },

  methods: {
    handleShowModal(subsType) {
      this.subsType = subsType;
      this.showModalSelect = true;
    },
  },
};
</script>
