<template>
  <form @submit.prevent="submit" name="depositForm" class="deposit_validate">
    <div class="form-group">
      <label class="mr-sm-2">Balance</label>
      <div class="input-group mb-3">
        <input
          :value="networkBalance + ' WUSD'"
          type="text"
          name="balance"
          class="form-control"
          placeholder="1000 WUSD"
          disabled
        />
      </div>
    </div>

    <validate-field
      title="Amount"
      field="amount"
      :custom-errors="customErrors.amount"
      :validations="$v.form.amount"
    >
      <div class="input-group mb-3">
        <input
          v-model="form.amount"
          @input="handleInputNumberOnly"
          type="text"
          name="amount"
          class="form-control"
          placeholder="1000"
          autocomplete="off"
        />
      </div>
    </validate-field>

    <button
      type="submit"
      :disabled="loading"
      class="btn btn-success btn-block mt-3"
    >
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span v-else>Submit</span>
    </button>
  </form>
</template>

<script>
// stores
import { useDepositStore } from "../../stores/deposit-store";
import { useProfileStore } from "../../stores/profile-store";

import validateField from "@/components/ValidateField.vue";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  components: { validateField },

  data() {
    return {
      form: {
        amount: null,
        currencyNetwork: "",
      },
      customErrors: {
        amount: {
          minValue: "The minimum deposit required is 300 USD",
        },
      },
      depositStore: useDepositStore(),
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      amount: { required, minValue: minValue(300) },
    },
  },

  computed: {
    loading() {
      return this.depositStore.createLoading;
    },
    networkBalance() {
      return this.profileStore.networkBalance?.balance;
    },
  },

  mounted() {
    this.profileStore.getNetworkBalance();
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.depositStore.postDepositCreate(
          this.form.amount
        );
      }
    },

    handleInputNumberOnly() {
      this.form.amount = this.form.amount.replace(/[^0-9]/g, "");
    },
  },
};
</script>
