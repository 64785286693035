@ -0,0 +1,133 @@
<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Personal Information</h4>
    </div>
    <div class="card-body">
      <form
        @submit.prevent="submit"
        name="personam_information"
        class="personal_information"
      >
        <div class="form-row">
          <div class="form-group col-xl-6">
            <label for="email" class="mr-sm-2">Email</label>
            <input
              :value="profile?.orang_email"
              type="email"
              name="email"
              id="email"
              placeholder="hello@wavedex.io"
              class="form-control"
              disabled
            />
          </div>

          <validate-field
            title="Name"
            field="name"
            :validations="$v.form.name"
            class="col-xl-6"
          >
            <input
              v-model="form.name"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              class="form-control"
            />
          </validate-field>

          <validate-field
            title="Nickname"
            field="nickname"
            :validations="$v.form.nickname"
            class="col-xl-6"
          >
            <input
              v-model="form.nickname"
              type="text"
              name="nickname"
              id="nickname"
              placeholder="Nickname"
              class="form-control"
            />
          </validate-field>

          <div class="form-group col-xl-6">
            <label for="date_of_birth" class="mr-sm-2">Date of Birth</label>
            <input
              type="date"
              name="date_of_birth"
              id="date_of_birth"
              placeholder="Date of Birth"
              class="form-control date_input"
            />
          </div>

          <div class="form-group col-xl-6">
            <label for="place_of_birth" class="mr-sm-2">Place of Birth</label>
            <input
              type="text"
              name="place_of_birth"
              id="place_of_birth"
              placeholder="Place of Birth"
              class="form-control"
            />
          </div>

          <validate-field
            title="Phone Number"
            field="phone_number"
            :validations="$v.form.mobileNumber"
            class="col-xl-6"
          >
            <input
              v-model="form.mobileNumber"
              type="text"
              name="phone_number"
              id="phone_number"
              placeholder="Phone Number"
              class="form-control"
            />
          </validate-field>

          <div class="form-group col-xl-6">
            <label for="address" class="mr-sm-2">Address</label>
            <input
              v-model="form.address"
              type="text"
              name="address"
              id="address"
              placeholder="Address"
              class="form-control"
            />
          </div>

          <div class="form-group col-xl-6">
            <label for="referral_code" class="mr-sm-2">Referral Code</label>
            <input
              :value="profile?.orang_refid_shadow"
              type="text"
              name="referral_code"
              id="referral_code"
              placeholder="Referral Code"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="upline" class="mr-sm-2">Upline</label>
            <input
              :value="profile?.upline_orang_obj.orang_refid_shadow"
              type="text"
              name="upline"
              id="upline"
              placeholder="Upline"
              class="form-control"
              disabled
            />
          </div>
          <div class="form-group col-xl-6">
            <label for="referral_link" class="mr-sm-2">Referral Link</label>
            <input
              :value="`https://www.wavedex.io/auth/sign-up?ref=${profile?.orang_refid_shadow}`"
              type="text"
              name="referral_link"
              id="referral_link"
              placeholder="Referral Link"
              class="form-control pr-5"
              disabled
            />
            <copyButton
              :value="`https://www.wavedex.io/auth/sign-up?ref=${profile?.orang_refid_shadow}`"
            />
          </div>

          <div class="form-group col-12">
            <button
              type="submit"
              :disabled="loading"
              class="btn btn-success pl-5 pr-5"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

// modules
import { alpha, numeric } from "vuelidate/lib/validators";

// components
import copyButton from "../../components/global/CopyButton.vue";
import validateField from "@/components/ValidateField.vue";

export default {
  components: { copyButton, validateField },

  props: {
    profile: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      form: {
        name: null,
        nickname: null,
        address: null,
        mobileNumber: null,
      },
      profileStore: useProfileStore(),
    };
  },

  validations: {
    form: {
      name: {},
      nickname: { alpha },
      mobileNumber: { numeric },
    },
  },

  computed: {
    loading() {
      return this.profileStore.profileUpdateLoading;
    },
  },

  watch: {
    profile: {
      handler(newVal) {
        this.form.name = newVal?.orang_name;
        this.form.nickname = newVal?.orang_nickname;
        this.form.address = newVal?.orang_address;
        this.form.mobileNumber = newVal?.orang_mobilenumber;
      },
    },
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.profileStore.postProfileUpdate(
          this.form.name,
          this.form.nickname,
          this.form.address,
          this.form.mobileNumber
        );
      }
    },
  },
};
</script>

<style scoped>
.date_input {
  color-scheme: dark;
}
</style>
