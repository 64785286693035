<template>
  <content-body>
    <div class="row">
      <div class="col-xl-5 col-lg-5 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="buy-sell-widget">
              <b-tabs v-model="activeTab">
                <b-tab title="Deposit"><deposit-form /></b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-lg-7 col-md-12">
        <img src="/images/deposit.png" alt="deposit" />
      </div>
    </div>
    <div>
      <DepositTransactionHistory />
    </div>
  </content-body>
</template>

<script>
import contentBody from "@/components/dashboard/ContentBody.vue";
import TransferForm from "@/components/transfer/TransferForm.vue";
import DepositForm from "@/components/deposit/DepositForm.vue";
import DepositTransactionHistory from "@/components/deposit/TransactionHistory.vue";
import app from "@/App.vue";

export default {
  extends: app,
  components: {
    contentBody,
    TransferForm,
    DepositForm,
    DepositTransactionHistory,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 469px) {
  .col-xl-7 img {
    width: 300px;
  }
}
</style>
