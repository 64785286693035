<template>
  <div id="footerI">
    <div class="container-fluid pb-2">
      <div class="row align-items-center justify-content-center">
        <!-- <div class="col-xl-6 col-md-6">
          <div class="copy_right">
            Copyright © {{ year }} Wavedex.io . All Rights Reserved.
          </div>
        </div> -->
        <div class="col-xl-6 col-md-6 text-center">
          <div class="social">
            <a href="https://www.tiktok.com/@wavedex" target="_blank"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
                /></svg
            ></a>
            <a href="https://www.instagram.com/wavedex" target="_blank"
              ><i class="fa fa-instagram"></i
            ></a>
            <a href="https://twitter.com/WaveDexOfficial" target="_blank"
              ><i class="fa fa-twitter"></i
            ></a>
            <a href="https://www.facebook.com/WaveDexOfficial" target="_blank"
              ><i class="fa fa-facebook"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from "@/App.vue";

export default {
  extends: app,
};
</script>

<style>
#footerI {
  padding-left: 70px;
}
@media only screen and (max-width: 500px) {
  .copy_right {
    font-size: 14px;
    text-align: center;
  }
  #footerI {
    padding-left: 0;
    margin-bottom: 40px;
  }
}
</style>
