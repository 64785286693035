<template>
  <div class="active-subscription-notice">
    <div class="notice-content">
      <i class="las la-check-circle"></i>
      <h2>Subscription Active</h2>
      <p>Your subscription is still active. Thank you for being with us!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveSubscriptionNotice",
};
</script>

<style scoped>
.active-subscription-notice {
  text-align: center;
  padding: 20px;
  border: 2px solid #28a745;
  border-radius: 10px;
  background-color: #d4edda;
  color: #155724;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notice-content i {
  font-size: 2.5rem;
  color: #28a745;
  margin-bottom: 10px;
}

.notice-content h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #28a745;
}

.notice-content p {
  font-size: 1rem;
  margin: 0;
}
</style>
