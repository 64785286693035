<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header border-0">
          <h4 class="card-title">Transaction History</h4>
        </div>
        <div class="card-body pt-0">
          <div v-if="loading" class="chart-stat py-5 text-center">
            <span
              class="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
          </div>

          <div v-else-if="history" class="transaction-table">
            <div class="table-responsive">
              <table
                class="table mb-0 table-responsive-sm text-center text-nowrap"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Address</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction of history" :key="transaction.id">
                    <td class="d-flex justify-content-center">
                      <span class="buy-thumb bg-primary">{{
                        transaction.id
                      }}</span>
                    </td>
                    <td>{{ transaction.to_address }}</td>
                    <td class="text-success">{{ transaction.amount }}</td>
                    <td>{{ formatDate(transaction.created_at) }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="d-flex justify-content-center">
                      <span class="buy-thumb bg-primary">1</span>
                    </td>
                    <td>0x1234567890abcdef1234567890abcdef12345678</td>
                    <td class="text-success">100,000</td>
                    <td>01/01/2024</td>
                  </tr>
                  <tr>
                    <td class="d-flex justify-content-center">
                      <span class="buy-thumb bg-primary">2</span>
                    </td>
                    <td>0x1234567890abcdef1234567890abcdef12345678</td>
                    <td class="text-success">200,000</td>
                    <td>01/02/2024</td>
                  </tr>
                  <tr>
                    <td class="d-flex justify-content-center">
                      <span class="buy-thumb bg-primary">3</span>
                    </td>
                    <td>0x1234567890abcdef1234567890abcdef12345678</td>
                    <td class="text-success">300,000</td>
                    <td>01/03/2024</td>
                  </tr>
                  <tr>
                    <td class="d-flex justify-content-center">
                      <span class="buy-thumb bg-primary">4</span>
                    </td>
                    <td>0x1234567890abcdef1234567890abcdef12345678</td>
                    <td class="text-success">400,000</td>
                    <td>01/04/2024</td>
                  </tr>
                  <tr>
                    <td class="d-flex justify-content-center">
                      <span class="buy-thumb bg-primary">5</span>
                    </td>
                    <td>0x1234567890abcdef1234567890abcdef12345678</td>
                    <td class="text-success">500,000</td>
                    <td>01/05/2024</td>
                  </tr> -->
                  <!-- <tr>
                    <td>
                      <span class="buy-thumb bg-primary">2</span>
                    </td>
                    <td>
                      <span class="badge badge-success">Buy</span>
                    </td>
                    <td><i class="cc LTC"></i> LTC</td>
                    <td>Using - Card *******8475</td>
                    <td class="text-success">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="sold-thumb"
                        ><i class="la la-arrow-down"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-danger">Sold</span>
                    </td>
                    <td><i class="cc XRP"></i> XRP</td>
                    <td>Using - Card *******8475</td>
                    <td class="text-danger">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="buy-thumb"
                        ><i class="la la-arrow-up"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-success">Buy</span>
                    </td>
                    <td><i class="cc DASH"></i> DASH</td>
                    <td>Using - Card *******2321</td>
                    <td class="text-success">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="sold-thumb"
                        ><i class="la la-arrow-down"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-danger">Sold</span>
                    </td>
                    <td><i class="cc BTC"></i> BTC</td>
                    <td>Using - Card *******2321</td>
                    <td class="text-danger">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="sold-thumb"
                        ><i class="la la-arrow-down"></i
                      ></span>
                    </td>

                    <td>
                      <span class="badge badge-danger">Sold</span>
                    </td>
                    <td><i class="cc BTC"></i> BTC</td>
                    <td>Using - Bank *******5264</td>
                    <td class="text-danger">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="buy-thumb"
                        ><i class="la la-arrow-up"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-success">Buy</span>
                    </td>
                    <td><i class="cc LTC"></i> LTC</td>
                    <td>Using - Card *******8475</td>
                    <td class="text-success">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="sold-thumb"
                        ><i class="la la-arrow-down"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-danger">Sold</span>
                    </td>
                    <td><i class="cc XRP"></i> XRP</td>
                    <td>Using - Card *******8475</td>
                    <td class="text-danger">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="buy-thumb"
                        ><i class="la la-arrow-up"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-success">Buy</span>
                    </td>
                    <td><i class="cc DASH"></i> DASH</td>
                    <td>Using - Card *******2321</td>
                    <td class="text-success">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="sold-thumb"
                        ><i class="la la-arrow-down"></i
                      ></span>
                    </td>
                    <td>
                      <span class="badge badge-danger">Sold</span>
                    </td>
                    <td><i class="cc BTC"></i> BTC</td>
                    <td>Using - Card *******2321</td>
                    <td class="text-danger">-0.000242 BTC</td>
                    <td>-0.125 USD</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>

          <div v-else class="chart-stat py-5 text-center">
            <i class="fa fa-search fa-3x mb-3" aria-hidden="true"></i>
            <h4>No data have been recorded</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// stores
import { useTransferStore } from "../../stores/transfer-store";

export default {
  data() {
    return {
      transferStore: useTransferStore(),
    };
  },

  computed: {
    history() {
      return this.transferStore.transferHistory;
    },
    loading() {
      return this.transferStore.historyLoading;
    },
  },

  mounted() {
    this.transferStore.getTransactionHistory();
  },

  methods: {
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return date.toLocaleString("en-GB", options);
    },
  },
};
</script>
