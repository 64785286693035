<template>
  <form-page>
    <template #header>
      <h4 class="card-title">Sign in</h4>
    </template>

    <form @submit.prevent="submit" class="signin_validate">
      <validate-field
        title="Email"
        field="email"
        :validations="$v.form.email"
        placeholder="hello@wavedex.io"
      />

      <validate-field
        title="Password"
        field="password"
        :custom-errors="customErrors.password"
        :validations="$v.form.password"
      >
        <input
          v-model="form.password"
          id="password"
          type="password"
          name="password"
          class="form-control"
          placeholder="Enter password"
          ref="password"
        />
        <toggle-password @is-password-show="togglePasswordValue" />
      </validate-field>

      <div class="form-row d-flex justify-content-between mt-4 mb-2">
        <div class="form-group mb-0">
          <label class="toggle">
            <input
              class="toggle-checkbox"
              type="checkbox"
              ref="rememberMeCheckbox"
            />
            <div class="toggle-switch"></div>
            <span class="toggle-label">Remember me</span>
          </label>
        </div>
        <div class="form-group mb-0">
          <router-link to="reset-password">Forgot Password?</router-link>
        </div>
      </div>
      <div class="text-center">
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-success btn-block"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-else>Sign in</span>
        </button>
      </div>
    </form>
    <div class="new-account mt-3">
      <p class="mb-0">
        Don't have an account?
        <router-link class="text-primary" to="sign-up">Sign up</router-link>
      </p>
      <p>
        Need a new verification link?
        <router-link class="text-primary" to="request-email-verification"
          >Resend</router-link
        >
      </p>
    </div>
  </form-page>
</template>

<script>
// stores
import { useAuthStore } from "../../stores/auth-store";

// modules
import { required, email } from "vuelidate/lib/validators";

// components
import formPage from "@/components/FormPage.vue";
import validateField from "@/components/ValidateField.vue";
import togglePassword from "../../components/global/TogglePassword.vue";

export default {
  components: { validateField, formPage, togglePassword },

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      customErrors: {
        password: { required: "Please provide a password" },
      },
      authStore: useAuthStore(),
    };
  },

  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },

  computed: {
    loading() {
      return this.authStore.loading;
    },
  },

  mounted() {
    this.getEmailPass();
  },

  methods: {
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        await this.authStore.postSignIn(this.form.email, this.form.password);

        // berfungsi untuk menyimpan email & password jika checkbox remember me aktif
        this.rememberMeHandler();
      }
    },

    saveEmailPass() {
      const encryptEmail = btoa(this.form.email);
      const encryptPass = btoa(this.form.password);

      localStorage.setItem("exm@l", encryptEmail);
      localStorage.setItem("pxm@l", encryptPass);
    },

    getEmailPass() {
      const encryptedEmail = localStorage.getItem("exm@l");
      const encryptedPass = localStorage.getItem("pxm@l");

      if (encryptedEmail && encryptedPass) {
        this.$refs.rememberMeCheckbox.checked = true;
        const decryptEmail = atob(encryptedEmail);
        const decryptPass = atob(encryptedPass);
        this.form.email = decryptEmail;
        this.form.password = decryptPass;
      }
    },

    rememberMeHandler() {
      if (
        this.$refs.rememberMeCheckbox.checked &&
        this.form.email &&
        this.form.password
      ) {
        this.saveEmailPass();
      } else {
        localStorage.removeItem("exm@l");
        localStorage.removeItem("pxm@l");
      }
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },
  },
};
</script>
