<template>
  <div class="card sc_wrap">
    <div class="card-header">
      <h4 class="card-title">Withdraw Address</h4>
    </div>
    <div class="card-body">
      <div class="col-xl-6">
        <WalletAddress />
      </div>
    </div>
  </div>
</template>

<script>
// components
import WalletAddress from "../../../components/wallet/WalletAddress.vue";

export default {
  components: { WalletAddress },
};
</script>

<style scoped>
.sc_wrap {
  min-height: 75vh;
}

@media only screen and (max-width: 500px) {
  .sc_wrap {
    height: auto;
  }
}
</style>
