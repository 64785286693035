<template>
  <div class="btn_pg-wrap">
    <span
      >Page: <strong>{{ page }}</strong></span
    >
    <div class="btn_pg">
      <button v-if="canGoBack" @click="handleBack" :disabled="isLoading">
        <span v-if="!isLoading">Back</span>
        <span v-else>Loading...</span>
      </button>
      <button v-if="canLoadMore" @click="handleLoadMore" :disabled="isLoading">
        <span v-if="!isLoading">Next Page</span>
        <span v-else>Loading...</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isBackLoading: {
      type: Boolean,
      // required: true,
    },
    canGoBack: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    canLoadMore: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleBack() {
      this.$emit("go-back");
    },
    handleLoadMore() {
      this.$emit("load-more");
    },
  },
};
</script>

<style scoped>
.btn_pg-wrap {
  display: flex;
  justify-content: space-between;
}
.btn_pg {
  display: flex;
  gap: 30px;
}
.btn_pg button {
  background-color: #7b6fff;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 2px 10px;
  font-size: 14px;
}
.btn_pg button:hover {
  background-color: #4135c3;
}

span {
  font-size: 14px;
}
</style>
