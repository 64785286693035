<template>
  <div class="card balance-widget">
    <div class="card-header border-0 py-0">
      <h4 class="card-title">Account</h4>
    </div>
    <div class="card-body">
      <div class="chart-content text-center">
        <div class="row">
          <div class="col-12">
            <div class="chart-stat">
              <div class="form-group m-0">
                <p class="mb-1">Referral Link</p>
                <input
                  :value="`https://www.wavedex.io/auth/sign-up?ref=${profile?.orang_refid_shadow}`"
                  type="text"
                  name="referral_link"
                  id="referral_link"
                  placeholder="Referral Link"
                  class="form-control pr-5 text-center"
                  disabled
                />
                <CopyButton
                  :value="`https://www.wavedex.io/auth/sign-up?ref=${profile?.orang_refid_shadow}`"
                  :top="42"
                />
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="chart-stat">
              <div class="form-group m-0">
                <p class="mb-1">Wallet Address</p>
                <input
                  :value="address"
                  type="text"
                  name="wallet_address"
                  id="wallet_address"
                  placeholder="Wallet Address"
                  class="form-control pr-5 text-center"
                  disabled
                />
                <CopyButton :value="address" :top="42" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// stores
import { useProfileStore } from "../../stores/profile-store";

// components
import CopyButton from "../global/CopyButton.vue";

export default {
  components: { CopyButton },

  data() {
    return {
      profileStore: useProfileStore(),
    };
  },

  computed: {
    profile() {
      return this.profileStore.profile;
    },
    address() {
      return this.profileStore.address;
    },
  },

  mounted() {
    this.profileStore.getProfile();
    this.profileStore.getAddress();
  },
};
</script>
