import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_API } from "../applibs/http-common";
import { toast } from "../helpers/toast";

export const usewithdrawStore = defineStore("withdraw", () => {
  // data state
  const withdrawHistory = ref(null);

  // loading state
  const createLoading = ref(false);
  const historyLoading = ref(false);

  async function postwithdrawCreate(label, amount) {
    try {
      createLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "post",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/withdraws/create`,
        data: {
          label,
          amount,
        },
      });

      if (res.data.success) {
        if (res.data.msg) {
          toast.success(res.data.msg);
        } else {
          toast.success("Your withdrawal has been successfully processed");
        }

        // berfungsi untuk refresh data
        getTransactionHistory(1, 5, "desc");
      } else {
        toast.warning(res.data.msg);
      }
    } catch (err) {
      console.error(err);
      toast.error("Unexpected Error");
    } finally {
      createLoading.value = false;
    }
  }

  async function getTransactionHistory(page, rows, order) {
    try {
      historyLoading.value = true;

      const token = Cookies.get("jwt");
      const res = await axios({
        method: "get",
        headers: {
          JWT: token,
        },
        url: `${BASE_API}/withdraws?page=${page}&rows=${rows}&order=${order}`,
      });

      if (res.data.success && res.data.data.datas.length > 0) {
        withdrawHistory.value = res.data.data.datas;
      }
    } catch (err) {
      console.error(err);
    } finally {
      historyLoading.value = false;
    }
  }

  return {
    withdrawHistory,
    createLoading,
    historyLoading,
    postwithdrawCreate,
    getTransactionHistory,
  };
});
