<template>
  <button
    @click="copyHandler"
    type="button"
    ref="toggleButton"
    :style="positionStyles"
    class="d-flex align-items-center p-0 px-2 pr-3 m-0 bg-transparent text-muted"
  >
    <i class="las la-copy" style="font-size: 1.5rem"></i>
  </button>
</template>

<script>
export default {
  props: {
    value: String,
    top: { type: Number, default: 43.5 },
    right: { type: Number, default: 1 },
  },

  computed: {
    positionStyles() {
      return {
        top: `${this.top}%`,
        right: `${this.right}%`,
      };
    },
  },

  mounted() {
    this.$nextTick(() => {
      const button = this.$refs.toggleButton;
      if (button.classList.contains("waves-effect")) {
        button.classList.remove("waves-effect");
      }
    });
  },

  methods: {
    copyHandler() {
      if (this.value) {
        navigator.clipboard.writeText(this.value);
        this.$toast.success("Text successfully copied");
      }
    },
  },
};
</script>

<style scoped>
button {
  width: max-content;
  height: 56.5%;
  border: none;
  position: absolute;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
</style>
