import Vue from "vue";
import { markRaw } from "vue";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";
import Toast from "vue-toastification";
import { createPinia, PiniaVuePlugin } from "pinia";
import routes from "./routes";
import app from "./App.vue";
// import "@mdi/font/css/materialdesignicons.css";
import "../public/css/base.css";

import "vue-toastification/dist/index.css";

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 3,
  newestOnTop: true,
});
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

const router = new VueRouter({
  mode: "history",
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

const main = new Vue({
  el: "#app",
  components: { app },
  router,
  pinia,
  render: function (createElement) {
    return createElement(app);
  },
});
