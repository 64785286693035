import Vue from "vue";

/** 
 Toast ini dikhususkan untuk digunakan diluar komponen Vue, jika ingin digunakan didalam komponen Vue gunakan "this.$toast(message)"
*/
export const toast = {
  success(message, time = 5000) {
    Vue.$toast.success(message, { timeout: time });
  },
  warning(message, time = 5000) {
    Vue.$toast.warning(message, { timeout: time });
  },
  error(message, time = 5000) {
    Vue.$toast.error(message, { timeout: time });
  },
};
